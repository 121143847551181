<div class="journal-option">
  <div nz-row [nzGutter]="12">
    <div nz-col [nzSpan]="6" class="journal-option__title">Столбцы</div>
    <div nz-col [nzSpan]="18">
      <nz-transfer-select-sorting
        availableTitle="Доступные столбцы"
        selectedTitle="Выбранные столбцы"
        [availableList]="availableColumns"
        [selectedList]="selectedColumns"
        key="field"
        (selectedListChange)="onChangeSelected($event)"
        [disabled]="!!(isLoadingData$ | async)"></nz-transfer-select-sorting>
    </div>
  </div>
</div>
