import { reduce } from 'lodash-es';

export interface IUserRole {
  value: string;
  label: string;
}

export enum Role {
  // Администратор
  ADMIN = 'ADMIN',
  // Программист
  SOFTWARE_ENGINEER = 'SOFTWARE_ENGINEER',
  // Оператор
  OPERATOR = 'OPERATOR',
}

export const USER_ROLES = {
  ADMIN: 'Администратор',
  SOFTWARE_ENGINEER: 'Программист',
  OPERATOR: 'Оператор',
  '': 'Гость',
};

export const USER_ROLES_LIST: IUserRole[] = reduce(
  USER_ROLES,
  (prev: IUserRole[], label, value) => {
    if (value !== '') {
      prev.push({ value, label });
    }

    return prev;
  },
  [],
);
