import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'journal-checked-radio',
  templateUrl: './journal-checked-radio.component.html',
  styleUrls: ['./journal-checked-radio.component.less'],
})
export class JournalCheckedRadioComponent {
  /**
   * Значение
   */
  @Input({ required: true }) name = 'radio';
  /**
   * Значение
   */
  @Input({ required: true }) value: string | number = '';
  /**
   * Выбрано
   */
  @Input({ required: true }) checked = false;
  /**
   * Событие изменения
   */
  @Output() changeState: EventEmitter<boolean> = new EventEmitter<boolean>();
}
