<ng-container *ngIf="form" [formGroup]="form">
  <filter-condition
    *ngIf="filterCondition.length > 0"
    [form]="form"
    [label]="filter?.label"
    [filterCondition]="filterCondition">
    <nz-form-item>
      <nz-form-control nzErrorTip="Выберите из списка">
        <nz-input-group [nzSuffix]="suffixIcon">
          <input
            #input
            type="text"
            autocomplete="off"
            placeholder="Выберите элементы"
            nz-input
            [disabled]="form.get('value')?.disabled"
            [value]="referenceValue"
            (focus)="input.blur()"
            (click)="handleOpenModal(); input.click()" />
        </nz-input-group>
        <ng-template #suffixIcon>
          <span class="ant-input-suffix" nz-icon nzType="table" (click)="input.click()"></span>
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  </filter-condition>
</ng-container>

<nz-modal
  [(nzVisible)]="isVisibleModal"
  nzCentered
  nzWidth="90%"
  [nzBodyStyle]="{ height: '90vh' }"
  nzOkText="Выбрать"
  [nzClosable]="false"
  (nzOnCancel)="handleCancel()"
  (nzAfterOpen)="onAfterOpen()"
  (nzOnOk)="handleOk()">
  <ng-container *nzModalContent>
    <div *ngIf="params?.config; else noConfig" class="reference-content">
      <nz-journal
        #journal
        [sysName]="params.sysName"
        [dataProvider]="params.dataProvider"
        [config]="params.config"
        [checked]="params.checked"
        [initWithData]="true">
      </nz-journal>
    </div>
    <ng-template #noConfig>
      <nz-alert nzType="warning" nzShowIcon nzMessage="Не заданы параметры журнала"></nz-alert>
    </ng-template>
  </ng-container>
</nz-modal>
