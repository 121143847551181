import { Component, OnInit } from '@angular/core';
import { FilterBaseComponent } from '../base/filter-base.component';
import { FILTER_CONDITION_DATE } from '../base/filter-condition-date.const';
import { untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'filter-date',
  templateUrl: './filter-date.component.html',
  styleUrls: ['../base/filter-base.components.less', './filter-date.component.less'],
})
export class FilterDateComponent extends FilterBaseComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
    if (!this.filter) return;

    if (!this.filter.inline) {
      this.filterCondition = this.filter.condition || FILTER_CONDITION_DATE;
    }

    this.form
      .get('value')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((val) => {
        if (!this.filter?.inline && this.viewValue === 'some') {
          this.form.get('valueStart')?.setValue(val ? val[0] : null);
          this.form.get('valueFinish')?.setValue(val ? val[1] : null);
        }
      });

    this.init();
  }
}
