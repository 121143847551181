import { Observable } from 'rxjs';
import { IJournal, JournalCheckedType } from 'src/app/lib/journal/types/journal.type';

export const JOURNAL_FILTER_TYPE_TEXT = 'text';
export const JOURNAL_FILTER_TYPE_NUMBER = 'number';
export const JOURNAL_FILTER_TYPE_SELECT = 'select';
export const JOURNAL_FILTER_TYPE_MULTISELECT = 'multiselect';
export const JOURNAL_FILTER_TYPE_DATE = 'date';
export const JOURNAL_FILTER_TYPE_DATETIME = 'datetime';
export const JOURNAL_FILTER_TYPE_CHECKBOX = 'checkbox';
export const JOURNAL_FILTER_TYPE_REFERENCE = 'reference';

export type JournalFilterType =
  | typeof JOURNAL_FILTER_TYPE_TEXT
  | typeof JOURNAL_FILTER_TYPE_NUMBER
  | typeof JOURNAL_FILTER_TYPE_SELECT
  | typeof JOURNAL_FILTER_TYPE_MULTISELECT
  | typeof JOURNAL_FILTER_TYPE_DATE
  | typeof JOURNAL_FILTER_TYPE_DATETIME
  | typeof JOURNAL_FILTER_TYPE_CHECKBOX
  | typeof JOURNAL_FILTER_TYPE_REFERENCE;

export interface IJournalFilter {
  // название фильтра
  label: string;
  // значение
  field: string;
  // тип, по-умолчанию JOURNAL_FILTER_TYPE_TEXT
  type?: JournalFilterType;
  // видимый элемент фильтра?, по-умолчанию false
  visible?: boolean;
  // в колонке, по-умолчанию false
  inline?: boolean;
  // активный (включена галочка)?, по-умолчанию false
  enable?: boolean;
  // обязательный?
  required?: boolean;
  // настройки
  params?: IJournalFilterDataSelect | IJournalFilterDataJournal;
  // условия
  condition?: IJournalFilterCondition[];
  // условие по-умолчанию
  defaultCondition?: JournalFilterConditionType;
  // значение по-умолчанию
  defaultValue?: any;
}

export interface IJournalFilterDataSelect {
  list: Observable<any>;
  bindLabel: string;
  bindValue: string;
}

export interface IJournalFilterDataJournal {
  sysName: string;
  dataProvider: string;
  config: IJournal;
  checked: JournalCheckedType;
  // по умолчанию используется primaryKey
  bindValue?: string;
}

export interface IJournalFilterCondition {
  sysName: JournalFilterConditionType | string;
  name: string;
}

export interface IJournalFilterParam {
  field: string;
  condition?: JournalFilterConditionType;
  enable: boolean;
  value: unknown | null;
  valueStart?: string | null;
  valueFinish?: string | null;
}

export enum JournalFilterConditionType {
  equal = 'EQ',
  notEqual = 'NEQ',
  like = 'LIKE',
  ilike = 'ILIKE',
  moreEqual = 'GTE',
  after = 'GT',
  lessEqual = 'LTE',
  before = 'LT',
  between = 'BETWEEN',
  isNull = 'NEX',
  in = 'IN',
}

export type JournalFilterViewType = 'one' | 'some' | 'none';
