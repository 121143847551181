import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

export function codeMatchesValidator(code$: BehaviorSubject<string>): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    return control.value !== code$.getValue() ? { codeMatches: true } : null;
  };
}
