import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivationStart, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppService } from './shared/services/app/app.service';
import { filter, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { RouteData } from './shared/types/route.type';
import { ProfileService } from './shared/services/app/profile.service';
import { Logger } from 'src/app/shared/services/logger/logger.service';
import { SessionService } from 'src/app/shared/services/app/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private logger: Logger,
    private session: SessionService,
    private appService: AppService,
    private profileService: ProfileService,
  ) {
    // запоминаем URL при старте приложения
    if (!window.location.pathname.startsWith('/auth') && !this.session.getRedirectURL()) {
      this.session.setRedirectURL(window.location.pathname);
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route: ActivatedRoute) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
        mergeMap((route: ActivatedRoute) => route.data as Observable<RouteData>),
      )
      .subscribe((event) => {
        if (event.title) {
          this.titleService.setTitle(event.title);
        }
      });

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // загрузка bundle
        // this.eventsService.publish('request:start', null, 1);
      } else {
        if (event instanceof NavigationEnd) {
          // загрузка bundle завершена
          // this.eventsService.publish('request:finished', null, 0);
          setTimeout(() => {
            this.scrollTop();
          }, 50);
        }
      }
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof ActivationStart),
        map((event: any) => event.snapshot.data),
      )
      .subscribe((data) => {
        this.appService.routeData$.next(data as RouteData);
      });

    this.profileService.init().catch(this.logger.error);
  }

  /**
   * Наверх страницы
   */
  scrollTop() {
    window.scroll(0, 0);
    // FIXME: scroll content
    const content = document.querySelector('#content');
    if (content) {
      document.querySelector('#content')?.scroll(0, 0);
    }
  }
}
