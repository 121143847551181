import { Component, OnInit } from '@angular/core';
import { FilterBaseComponent } from '../base/filter-base.component';
import { FILTER_CONDITION_SELECT } from '../base/filter-condition-select.const';
import { get } from 'lodash-es';
import { IJournalFilterDataSelect } from '../../types/journal-filter.type';

@Component({
  selector: 'filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['../base/filter-base.components.less', './filter-select.component.less'],
})
export class FilterSelectComponent extends FilterBaseComponent implements OnInit {
  /**
   * Параметры
   */
  params!: IJournalFilterDataSelect;

  constructor() {
    super();
  }

  ngOnInit() {
    if (!this.filter) return;

    if (!this.filter.inline) {
      this.filterCondition = this.filter.condition || FILTER_CONDITION_SELECT;
    }

    this.params = this.filter.params as IJournalFilterDataSelect;

    this.init();
  }

  getLabel(item: object): string {
    return this.params?.bindLabel ? get(item, this.params.bindLabel) : '';
  }

  getValue(item: object): string {
    return this.params?.bindLabel ? get(item, this.params.bindValue) : '';
  }
}
