<nz-collapse nzGhost *ngIf="isFormFilter && availableFilter.length" class="journal-collapse">
  <nz-collapse-panel nzHeader="Фильтры" [(nzActive)]="isFilterVisible" (nzActiveChange)="onChangeActive()">
    <journal-filter [availableFilter]="availableFilter" [filtersParam]="filtersParam" [isLoadingData$]="isLoadingData$">
    </journal-filter>
  </nz-collapse-panel>
</nz-collapse>

<nz-collapse nzGhost *ngIf="config.columns" class="journal-collapse">
  <nz-collapse-panel nzHeader="Опции" [(nzActive)]="isOptionVisible" (nzActiveChange)="onChangeActive()">
    <journal-option
      [availableColumns]="config.columns || []"
      [(selectedColumns)]="columns"
      [isLoadingData$]="isLoadingData$">
    </journal-option>
  </nz-collapse-panel>
</nz-collapse>

<div class="journal-manage">
  <div class="journal-manage__btn">
    <button type="button" nz-button nzType="primary" (click)="onApply()" [disabled]="isLoadingData$ | async">
      <i nz-icon nzType="check" nzTheme="outline"></i> Применить
    </button>
    <button type="button" nz-button nzType="default" (click)="onReset()" [disabled]="isLoadingData$ | async">
      <i nz-icon nzType="close" nzTheme="outline"></i> Очистить
    </button>
  </div>
  <ng-content></ng-content>
</div>
