<div *ngIf="form" [formGroup]="form">
  <div nz-row [nzGutter]="12">
    <div nz-col class="gutter-col" [nzSpan]="6">
      <label nz-checkbox formControlName="enable">{{ label }}</label>
    </div>
    <nz-form-item nz-col class="gutter-col" [nzSpan]="6">
      <nz-form-control nzErrorTip="Выберите условие">
        <nz-select formControlName="condition">
          <ng-container *ngFor="let cond of filterCondition">
            <nz-option [nzValue]="cond.sysName" [nzLabel]="cond.name"></nz-option>
          </ng-container>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <div nz-col class="gutter-col" [nzSpan]="12">
      <ng-content></ng-content>
    </div>
  </div>
</div>
