import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { SidebarLayoutComponent } from './layouts/sidebar-layout/sidebar-layout.component';
import { SimpleLayoutComponent } from './layouts/simple-layout/simple-layout.component';
import { CustomRoute } from './shared/types/route.type';
import { Role } from './shared/consts/roles.enum';
import { Page404Component } from './layouts/components/page404/page404.component';
import {
  ROUTE_AUTH,
  ROUTE_BOARD,
  ROUTE_CRATE_MODULE,
  ROUTE_REGISTER,
  ROUTE_CHANNEL,
  ROUTE_CRATE,
  ROUTE_DIAGNOSTICS,
  ROUTE_DIAGRAMS,
  ROUTE_EXPERIMENT,
  ROUTE_EXPERIMENT_PLAN,
  ROUTE_SCREEN_CONFIGURATION,
  ROUTE_TELEMETRY_CONFIGURATION,
  ROUTE_USER,
  ROUTE_SCENARIO,
  ROUTE_SCREEN,
  ROUTE_VIRTUAL_MODULE,
  ROUTE_VIRTUAL_CHANNEL,
  ROUTE_SYSTEM,
  ROUTE_HELP,
} from 'src/app/shared/consts/routes.const';
import { CanMatchHelp } from 'src/app/guards/can-match-help.guard';

const routes: CustomRoute[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ROUTE_SYSTEM,
  },
  {
    path: '',
    component: SimpleLayoutComponent,
    children: [
      {
        path: ROUTE_AUTH,
        loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
        data: {
          title: 'Авторизация',
        },
      },
      {
        path: ROUTE_HELP,
        loadChildren: () => import('./pages/help/help.module').then((m) => m.HelpModule),
        data: {
          title: 'Справочная информация',
        },
        canMatch: [CanMatchHelp],
      },
    ],
  },
  {
    path: '',
    component: SidebarLayoutComponent,
    canActivate: [ AuthGuard ],
    children: [
      // {
      //   path: ROUTE_DIAGNOSTICS,
      //   loadChildren: () => import('./pages/diagnostics/diagnostics.module').then((m) => m.DiagnosticsModule),
      //   data: {
      //     title: 'Диагностика',
      //     hideBreadcrumb: true,
      //   },
      // },
      // {
      //   path: ROUTE_MANAGEMENT,
      //   loadChildren: () => import('./pages/management/management.module').then((m) => m.ManagementModule),
      //   data: {
      //     title: 'Управление',
      //     hideBreadcrumb: true,
      //   },
      // },
      {
        path: ROUTE_SYSTEM,
        loadChildren: () => import('./pages/system/system.module').then((m) => m.SystemModule),
        data: {
          title: 'Система',
          breadcrumb: 'Система',
        },
      },
      {
        path: ROUTE_USER,
        loadChildren: () => import('./pages/user/user.module').then((m) => m.UserModule),
        data: {
          title: 'Пользователи',
          breadcrumb: 'Пользователи',
        },
      },
      {
        path: ROUTE_CRATE,
        loadChildren: () => import('./pages/crate/crate.module').then((m) => m.CrateModule),
        data: {
          title: 'Крейты',
          breadcrumb: 'Крейты',
        },
      },
      {
        path: ROUTE_BOARD,
        loadChildren: () => import('./pages/board/board.module').then((m) => m.BoardModule),
        data: {
          title: 'Объекты контроля',
          breadcrumb: 'Объекты контроля',
        },
      },
      {
        path: ROUTE_CRATE_MODULE,
        loadChildren: () => import('./pages/crate-module/crate-module.module').then((m) => m.CrateModuleModule),
        data: {
          title: 'Универсальные модули (УЭМ)',
          breadcrumb: 'Универсальные модули (УЭМ)',
        },
      },
      {
        path: ROUTE_VIRTUAL_MODULE,
        loadChildren: () => import('./pages/virtual-module/virtual-module.module').then((m) => m.VirtualModuleModule),
        data: {
          title: 'Виртуальные модули',
          breadcrumb: 'Виртуальные модули',
        },
      },
      {
        path: ROUTE_REGISTER,
        loadChildren: () => import('./pages/register/register.module').then((m) => m.RegisterModule),
        data: {
          title: 'Регистры',
          breadcrumb: 'Регистры',
        },
      },
      {
        path: ROUTE_CHANNEL,
        loadChildren: () => import('./pages/channel/channel.module').then((m) => m.ChannelModule),
        data: {
          title: 'Каналы',
          breadcrumb: 'Каналы',
        },
      },
      {
        path: ROUTE_VIRTUAL_CHANNEL,
        loadChildren: () =>
          import('./pages/virtual-channel/virtual-channel.module').then((m) => m.VirtualChannelModule),
        data: {
          title: 'Виртуальные каналы',
          breadcrumb: 'Виртуальные каналы',
        },
      },
      {
        path: ROUTE_TELEMETRY_CONFIGURATION,
        loadChildren: () =>
          import('./pages/telemetry-configuration/telemetry-configuration.module').then(
            (m) => m.TelemetryConfigurationModule,
          ),
        data: {
          title: 'Конфигурации телеметрии',
          roles: [ Role.ADMIN ],
          breadcrumb: 'Конфигурации телеметрии',
        },
      },
      {
        path: ROUTE_SCREEN_CONFIGURATION,
        loadChildren: () =>
          import('./pages/screen-configuration/screen-configuration.module').then((m) => m.ScreenConfigurationModule),
        data: {
          title: 'Конфигурация экранов',
          breadcrumb: 'Конфигурация экранов',
        },
      },
      {
        path: ROUTE_SCENARIO,
        loadChildren: () => import('./pages/scenario/scenario.module').then((m) => m.ScenarioModule),
        data: {
          title: 'Сценарии',
          breadcrumb: 'Сценарии',
        },
      },
      {
        path: ROUTE_EXPERIMENT_PLAN,
        loadChildren: () =>
          import('./pages/experiment-plan/experiment-plan.module').then((m) => m.ExperimentPlanModule),
        data: {
          title: 'План испытаний',
          breadcrumb: 'План испытаний',
        },
      },
      {
        path: ROUTE_EXPERIMENT,
        loadChildren: () => import('./pages/experiment/experiment.module').then((m) => m.ExperimentModule),
        data: {
          title: 'Испытание',
          breadcrumb: 'Испытание',
        },
      },
      {
        path: ROUTE_DIAGRAMS,
        loadChildren: () => import('./pages/diagrams/diagrams.module').then((m) => m.DiagramsModule),
        data: {
          title: 'Телеметрия',
          breadcrumb: 'Телеметрия',
        },
      },
      {
        path: ROUTE_SCREEN,
        loadChildren: () => import('./pages/screen/screen.module').then((m) => m.ScreenModule),
        data: {
          title: 'Экран',
          hideBreadcrumb: true,
        },
      },
      {
        path: ROUTE_HELP,
        loadChildren: () => import('./pages/help/help.module').then((m) => m.HelpModule),
        data: {
          title: 'Справочная информация',
          hideBreadcrumb: true,
        },
      },
    ],
  },
  // 404
  {
    path: '**',
    component: Page404Component,
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
})
export class AppRoutingModule {
}
