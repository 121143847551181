import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NzMessageService } from 'ng-zorro-antd/message';
import { JournalComponent } from '../journal/journal.component';
import { IJournalColumn } from '../types/journal-column.type';
import { JournalRowType } from '../types/journal.type';

const ERROR_MESSAGE_SORTING = 'Ошибка изменения порядка элементов';

@Component({
  selector: 'journal-column-sorting',
  templateUrl: './journal-column-sorting.component.html',
  styleUrls: ['./journal-column-sorting.component.less'],
})
export class JournalColumnSortingComponent {
  /**
   * Текущая строка
   */
  @Input({ required: true }) row: JournalRowType;
  /**
   * Колонка для сортировки
   */
  @Input({ required: true }) column?: IJournalColumn;
  /**
   * Ссылка на компонент журнала
   */
  @Input({ required: true }) journal?: JournalComponent;

  constructor(
    private message: NzMessageService,
    private http: HttpClient,
  ) {}

  /**
   * Позиция выше
   */
  handleUp() {
    if (!this.journal || !this.column) return;

    this.http
      .get(this.journal.dataProvider + '/up/' + this.column.field + '/' + this.row[this.journal.primaryKey])
      .subscribe({
        next: () => {
          this.journal?.reloadData();
        },
        error: (error) => {
          this.message.error(error.error?.message || ERROR_MESSAGE_SORTING);
        },
      });
  }

  /**
   * Позиция ниже
   */
  handleDown() {
    if (!this.journal || !this.column) return;

    this.http
      .get(this.journal.dataProvider + '/down/' + this.column.field + '/' + this.row[this.journal.primaryKey])
      .subscribe({
        next: () => {
          this.journal?.reloadData();
        },
        error: (error) => {
          this.message.error(error.error?.message || ERROR_MESSAGE_SORTING);
        },
      });
  }
}
