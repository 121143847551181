import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  IJournalFilter,
  IJournalFilterCondition,
  JournalFilterConditionType,
  JournalFilterViewType,
} from '../../types/journal-filter.type';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  template: '',
})
export abstract class FilterBaseComponent {
  /**
   * Форма
   */
  @Input({ required: true }) form: FormGroup = new FormGroup({});
  /**
   * Параметры
   */
  @Input({ required: true }) filter?: IJournalFilter;

  /**
   * Типы фильтрации
   */
  filterCondition: IJournalFilterCondition[] = [];
  /**
   *  Отображение значений
   */
  viewValue: JournalFilterViewType = 'one';

  protected init() {
    // значение по умолчанию
    if (!this.filter?.inline && !this.form.get('condition')?.value) {
      // FIXME: а надо ли для основного фильтра?
      this.form.get('condition')?.setValue(this.filterCondition[0].sysName);
    }

    // Несколько полей ввода
    const hasMultipleValue =
      this.filterCondition.findIndex((condition) => condition.sysName === JournalFilterConditionType.between) !== -1;
    if (hasMultipleValue) {
      this.setViewValue(this.form.get('condition')?.value, this.form.get('enable')?.value);

      this.form
        .get('condition')
        ?.valueChanges.pipe(untilDestroyed(this))
        .subscribe((val) => {
          this.setViewValue(val, this.form.get('enable')?.value);
        });
    }

    // Нет полей ввода
    const hasNullValue =
      this.filterCondition.findIndex((condition) => condition.sysName === JournalFilterConditionType.isNull) !== -1;
    if (hasNullValue) {
      this.form
        .get('condition')
        ?.valueChanges.pipe(untilDestroyed(this))
        .subscribe((condition) => {
          if (condition === JournalFilterConditionType.isNull) {
            this.form.get('value')?.setValue(null);
          }
        });
    }
  }

  /**
   * Установка видимости нескольких компонентов
   */
  private setViewValue(condition: JournalFilterConditionType, enable: boolean) {
    if (!condition) {
      this.viewValue = 'none';
      if (enable) {
        this.form.get('value')?.disable();
        this.form.get('valueStart')?.disable();
        this.form.get('valueFinish')?.disable();
      }
      return;
    }

    if (condition === JournalFilterConditionType.between) {
      this.viewValue = 'some';
      if (enable) {
        this.form.get('value')?.disable();
        this.form.get('valueStart')?.enable();
        this.form.get('valueFinish')?.enable();
      }
    } else {
      this.viewValue = 'one';
      if (enable) {
        this.form.get('value')?.enable();
        this.form.get('valueStart')?.disable();
        this.form.get('valueFinish')?.disable();
      }
    }
  }
}
