/**
 * The available log levels.
 */
export enum Level {
  OFF = 0,
  ERROR = 1,
  WARN = 2,
  INFO = 3,
  DEBUG = 4,
  LOG = 5,
}
