import { FormArray, FormControl, FormGroup } from '@angular/forms';

export function formValidate(
  form: FormGroup | FormControl,
  opts?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  },
) {
  if (form instanceof FormControl) {
    form.markAsDirty();
    form.updateValueAndValidity(opts);
  } else {
    for (const i in form.controls) {
      if (form.controls[i] instanceof FormArray) {
        const formArray = form.controls[i] as FormArray;
        for (const control of formArray.controls) {
          formValidate(control as FormGroup);
        }
      } else if (form.controls[i] instanceof FormGroup) {
        const formGroup = form.controls[i] as FormGroup;
        for (const prop in formGroup.controls) {
          formValidate(formGroup.get(prop) as FormGroup);
        }
      } else {
        formValidate(form.controls[i] as FormGroup);
      }
    }
  }
}
