import { ControlValueAccessor, FormArray, FormControl, FormGroup, ValidationErrors, Validator } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

type FunctionType = (e?: unknown) => void;

@UntilDestroy()
export abstract class FormControlValueAccessorAdapter implements ControlValueAccessor, Validator {
  abstract formControl: FormControl | FormGroup | FormArray;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: FunctionType = () => {};

  writeValue(val: unknown): void {
    if (val) {
      this.formControl.setValue(val);
    }
  }

  registerOnChange(fn: FunctionType): void {
    this.formControl.valueChanges.pipe(untilDestroyed(this)).subscribe((e) => {
      fn(e);
    });
  }

  registerOnTouched(fn: FunctionType): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formControl.disable() : this.formControl.enable();
  }

  validate(): ValidationErrors | null {
    return this.formControl.valid
      ? null
      : {
          invalid: {
            value: this.formControl.value,
            message: `Control is invalid`,
          },
        };
  }
}
