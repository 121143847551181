import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IJournalColumn } from '../types/journal-column.type';
import { Observable } from 'rxjs';

@Component({
  selector: 'journal-option',
  templateUrl: './journal-option.component.html',
  styleUrls: ['./journal-option.component.less'],
})
export class JournalOptionComponent {
  /**
   * Все колонки журнала
   */
  @Input() availableColumns: IJournalColumn[] = [];
  /**
   * Выбранные колонки
   */
  @Input() selectedColumns: IJournalColumn[] = [];
  /**
   * Загрузка данных
   */
  @Input() isLoadingData$?: Observable<boolean>;
  /**
   * События
   */
  @Output() selectedColumnsChange: EventEmitter<IJournalColumn[]> = new EventEmitter();

  onChangeSelected($event: IJournalColumn[]) {
    this.selectedColumnsChange.emit($event);
  }
}
