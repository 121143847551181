import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { isString, keyBy, map, values } from 'lodash-es';
import { debounceTime, distinctUntilChanged, map as rxMap } from 'rxjs/operators';

import { JournalService } from '../services/journal.service';
import { IJournalColumn } from '../types/journal-column.type';
import { IJournalFilter, IJournalFilterParam, JournalFilterConditionType } from '../types/journal-filter.type';
import { dateBetweenValidator } from '../utils/validators';
import { controlName } from '../utils/helpers';
import { findInList } from '../utils/lodash';

@Component({
  selector: 'journal-filter-column',
  templateUrl: './journal-filter-column.component.html',
  styleUrls: ['./journal-filter-column.component.less'],
})
export class JournalFilterColumnComponent implements OnChanges {
  /**
   * Настройки фильтров
   */
  @Input({ required: true }) filters!: IJournalFilter[];
  /**
   * Активные колонки
   */
  @Input({ required: true }) columns!: IJournalColumn[];
  /**
   * Значения фильтров
   */
  @Input({ required: true }) filtersParam!: IJournalFilterParam[];
  /**
   * Выбранный
   */
  @Input() checked = false;
  /**
   * Есть действия над строкой
   */
  @Input() hasActions = false;
  /**
   * Обработчики
   */
  @Output() doFilter = new EventEmitter();
  /**
   * Настройки колонок
   */
  filterColumn: { [key: string]: IJournalFilter } = {};
  /**
   * Форма
   */
  form: FormGroup;

  constructor(private journalService: JournalService) {
    this.form = new FormGroup({});
  }

  ngOnChanges(changes: SimpleChanges): void {
    const columns = changes['columns']?.currentValue || this.columns;
    const filtersParam = changes['filtersParam']?.currentValue || this.filtersParam;

    if (filtersParam && columns) {
      this.createFilterForm(filtersParam, columns);
    }
  }

  /**
   * Переданный контрол
   * @param field
   */
  getControl(field: string): FormGroup {
    return this.form.get(controlName(field)) as FormGroup;
  }

  /**
   * Формируем форму для фильтров
   */
  private createFilterForm(filtersParam: IJournalFilterParam[], columns: IJournalColumn[]) {
    this.form = new FormGroup({});
    filtersParam.forEach((filterParam) => {
      const conditionControl = new FormControl(filterParam.condition, [Validators.required]);

      // фильтр есть только у колонки, которая рисуется
      if (findInList(columns, 'field', filterParam.field)) {
        let filterControl;
        if (filterParam.condition === JournalFilterConditionType.between) {
          filterControl = new FormGroup({
            field: new FormControl(filterParam.field),
            condition: conditionControl,
            enable: new FormControl(true),
            valueStart: new FormControl(filterParam.valueStart, [dateBetweenValidator(conditionControl)]),
            valueFinish: new FormControl(filterParam.valueFinish),
          });
        } else {
          filterControl = new FormGroup({
            field: new FormControl(filterParam.field),
            condition: conditionControl,
            enable: new FormControl(true),
            value: new FormControl(filterParam.value),
          });
        }

        this.form.addControl(controlName(filterParam.field), filterControl);
      }
    });

    const inlineFilter = this.filters.filter((filter) => filter.inline);
    this.filterColumn = keyBy(inlineFilter, (item) => {
      return item.field;
    });

    setTimeout(() => {
      this.form.valueChanges
        .pipe(
          debounceTime(500),
          rxMap((controls) =>
            map(controls, (control) => {
              control.value = isString(control.value) ? control.value.trim() : null;
              return control;
            }),
          ),
          distinctUntilChanged(),
        )
        .subscribe(() => {
          this.onDoFilter();
        });
    }, 50);
  }

  /**
   * Фильтрация
   */
  onDoFilter() {
    if (this.form.valid) {
      this.doFilter.emit(values(this.form.getRawValue()));
    }

    return false;
  }
}
