import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalConfirmCodeComponent } from './modal-confirm-code.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';

@NgModule({
  imports: [
    CommonModule,
    NzModalModule,
    ReactiveFormsModule,
    FormsModule,
    NzFormModule,
    NzButtonModule,
    NzInputModule,
  ],
  declarations: [ModalConfirmCodeComponent],
  exports: [ModalConfirmCodeComponent],
})
export class ModalConfirmCodeModule {}
