import { JournalRowType } from './journal.type';

export const JOURNAL_ACTION_EDIT: IJournalAction = {
  title: 'Редактировать',
  icon: 'edit',
  onClick: (row: JournalRowType) => {
    // nothing
  },
};

export const JOURNAL_ACTION_VIEW: IJournalAction = {
  title: 'Просмотр',
  icon: 'eye',
  onClick: (row: JournalRowType) => {
    // nothing
  },
};

export const JOURNAL_ACTION_COPY: IJournalAction = {
  title: 'Создать копию',
  icon: 'copy',
  onClick: (row: JournalRowType) => {
    console.log(row);
  },
};

export const JOURNAL_ACTION_REMOVE: IJournalAction = {
  title: 'Удалить',
  icon: 'delete',
  styleClass: 'text-danger',
  onClick: (row: JournalRowType) => {
    // nothing
  },
};

export const JOURNAL_ACTION_RESTORE: IJournalAction = {
  title: 'Восстановить',
  icon: 'rollback',
  styleClass: 'text-info',
  onClick: (row: JournalRowType) => {
    // nothing
  },
};

export const JOURNAL_ACTION_PERMANENT_REMOVE: IJournalAction = {
  title: 'Полное удаление',
  icon: 'delete',
  styleClass: 'text-danger',
  onClick: (row: JournalRowType) => {
    // nothing
  },
};

export interface IJournalAction {
  // Название
  title: string;
  // класс стиля
  styleClass?: string;
  // иконка
  icon: string;
  // событие клика
  onClick: (row: JournalRowType) => void;
  // функция блокировки кнопки
  disableFn?: (row: JournalRowType) => boolean;
  // функция сокрытия кнопки
  hideFn?: (row: JournalRowType) => boolean;
}
