<div class="transfer-select-sorting">
  <div class="transfer-select">
    <div class="transfer-select__label">{{ availableTitle }}</div>

    <select class="form-select" size="10" multiple="multiple" [(ngModel)]="available" [disabled]="disabled">
      <ng-container *ngFor="let item of availableList">
        <option *ngIf="!isSelectedItem(item)" [ngValue]="item" (dblclick)="toSelected()">{{ item.label }}</option>
      </ng-container>
    </select>
  </div>

  <div class="transfer-select__btn">
    <button type="button" nz-button (click)="toSelected()" [disabled]="available.length === 0 || disabled">
      <span nz-icon nzType="right" nzTheme="outline"></span>
    </button>

    <button type="button" nz-button (click)="removeSelected()" [disabled]="selected.length === 0 || disabled">
      <span nz-icon nzType="left" nzTheme="outline"></span>
    </button>
  </div>

  <div class="transfer-select">
    <div class="transfer-select__label">{{ selectedTitle }}</div>

    <select
      class="form-select"
      size="10"
      multiple="multiple"
      [(ngModel)]="selected"
      [disabled]="disabled"
      [class.is-valid]="valid"
      [class.is-invalid]="invalid">
      <ng-container *ngFor="let item of selectedList">
        <option [ngValue]="item" (dblclick)="removeSelected()">{{ item.label }}</option>
      </ng-container>
    </select>
  </div>

  <div class="transfer-select__btn">
    <button type="button" nz-button [disabled]="selected.length === 0 || disabled" (click)="moveTop()">
      <span nz-icon nzType="double-right" nzTheme="outline" class="rotate-90"></span>
    </button>

    <button type="button" nz-button [disabled]="selected.length === 0 || disabled" (click)="up()">
      <span nz-icon nzType="up" nzTheme="outline"></span>
    </button>

    <button type="button" nz-button [disabled]="selected.length === 0 || disabled" (click)="down()">
      <span nz-icon nzType="down" nzTheme="outline"></span>
    </button>

    <button type="button" nz-button [disabled]="selected.length === 0 || disabled" (click)="moveBottom()">
      <span nz-icon nzType="double-left" nzTheme="outline" class="rotate-90"></span>
    </button>
  </div>
</div>
