import { Component } from '@angular/core';

@Component({
  selector: 'app-page404',
  templateUrl: 'page404.component.html',
  styleUrls: ['page404.component.less'],
})
export class Page404Component {
  protected readonly window = window;
}
