import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { RouteData } from 'src/app/shared/types/route.type';
import { $WebSocket } from 'src/app/lib/websocket/websocket.service';
import { MESSAGE_ERROR_SERVICE_UNAVAILABLE } from 'src/app/shared/consts/messages.const';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { IJournalResult } from 'src/app/lib/journal/types/journal-query.type';
import { IScreenConfiguration } from 'src/app/pages/screen-configuration/types/screen-configuration.type';
import { IWebSocketMachine } from 'src/app/shared/types/response.type';
import { getSocketPath } from 'src/app/shared/helpers/websocket';
import { WebSocketSendMode } from 'src/app/lib/websocket/websocket.enum';
import { IBoard } from 'src/app/pages/board/types/board.type';
import { JournalFilterConditionType } from 'src/app/lib/journal/index';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  // @ts-ignore
  routeData$: BehaviorSubject<RouteData | null> = new BehaviorSubject(null);

  /**
   * WebSocket API
   * @private
   */
  public wsMachine!: $WebSocket<IWebSocketMachine>;
  public machine$: BehaviorSubject<IWebSocketMachine | null> = new BehaviorSubject<IWebSocketMachine | null>(null);
  /**
   * Выбранный борт
   */
  public chosenBoard$: BehaviorSubject<IBoard | null> = new BehaviorSubject<IBoard | null>(null);

  constructor(
    private message: NzMessageService,
    private http: HttpClient,
  ) {
    this.wsMachine = new $WebSocket(getSocketPath('/machine'), undefined, {
      maxTimeout: 86400000,
      reconnectIfNotNormalClose: true,
    });
  }

  /**
   * Информация о текущем выбранном борте
   */
  getBoard() {
    this.wsMachine.getDataStream().subscribe((res) => {
      if (res.status === 'error') {
        this.message.error(res?.message || MESSAGE_ERROR_SERVICE_UNAVAILABLE);
      } else {
        this.machine$.next(res.data);
        if (this.chosenBoard$.getValue()?.id !== res.data?.board?.id) {
          this.chosenBoard$.next(res.data.board);
        }
      }
    });
  }

  repeatResponseMachine() {
    return this.wsMachine.send({ repeatResponse: true }, WebSocketSendMode.Observable);
  }

  /**
   * Список экранов для меню
   */
  getScreens(boardId: number): Promise<IScreenConfiguration[]> {
    return firstValueFrom(
      this.http
        .post<IJournalResult<IScreenConfiguration>>('/layouts/search', {
          page: 0,
          perPage: 999,
          columns: ['id', 'name', 'roles', 'icon'],
          filters: [
            {
              field: 'board.id',
              operator: JournalFilterConditionType.equal,
              values: [boardId],
            },
            {
              field: 'showInNavigation',
              operator: JournalFilterConditionType.equal,
              values: [true],
            },
          ],
          order: [],
        })
        .pipe(map((res) => res.items)),
    );
  }
}
