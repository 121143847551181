import { isString, trim, isNil, isNumber, isNaN } from 'lodash-es';

/**
 * Пустое значение
 */
export function isBlank(value: unknown) {
  // не null или undefined, не пустая строка и не NaN
  return isNil(value) || (isString(value) && trim(value) === '') || (isNumber(value) && !isNaN(value));
}

/**
 * Поиск в множестве
 */
export function findInSet(set: Set<object>, cb: (e: object) => boolean) {
  for (const e of set) {
    if (cb(e)) {
      return e;
    }
  }
  return undefined;
}

/**
 * Значение не пустое
 */
export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function maximum<T>(a: T, b: T): T {
  return a > b ? a : b;
}

export function minimum<T>(a: T, b: T): T {
  return a < b ? a : b;
}
