<ng-container [formGroup]="form" *ngIf="form">
  <filter-condition
    *ngIf="filterCondition.length > 0"
    [form]="form"
    [label]="filter?.label"
    [filterCondition]="filterCondition">
    <nz-form-item *ngIf="viewValue === 'one'" nz-col class="gutter-col">
      <nz-form-control nzErrorTip="Выберите дату и время">
        <nz-date-picker formControlName="value" [nzFormat]="'dd.MM.yyyy HH:mm:ss'" [nzShowTime]="true"></nz-date-picker>
      </nz-form-control>
    </nz-form-item>

    <div *ngIf="viewValue === 'some'" nz-row [nzGutter]="6">
      <div nz-col class="gutter-col" [nzSpan]="12">
        <nz-form-item>
          <nz-form-control nzErrorTip="Выберите дату и время">
            <nz-date-picker
              formControlName="valueStart"
              [nzFormat]="'dd.MM.yyyy HH:mm:ss'"
              [nzShowTime]="true"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col class="gutter-col" [nzSpan]="12">
        <nz-form-item>
          <nz-form-control nzErrorTip="Выберите дату и время">
            <nz-date-picker
              formControlName="valueFinish"
              [nzFormat]="'dd.MM.yyyy HH:mm:ss'"
              [nzShowTime]="true"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </filter-condition>

  <ng-container *ngIf="filterCondition.length === 0">
    <ng-container *ngIf="viewValue === 'one'">
      <nz-date-picker formControlName="value" [nzFormat]="'dd.MM.yyyy HH:mm:ss'" [nzShowTime]="true"></nz-date-picker>
    </ng-container>
    <ng-container *ngIf="viewValue === 'some'">
      <nz-range-picker formControlName="value" [nzFormat]="'dd.MM.yyyy HH:mm:ss'" [nzShowTime]="true"></nz-range-picker>
    </ng-container>
  </ng-container>
</ng-container>
