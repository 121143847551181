<td *ngIf="checked"></td>
<ng-container *ngFor="let column of columns">
  <td>
    <ng-container *ngIf="form.get(column.field)">
      <ng-container *ngIf="filterColumn[column.field].type === 'text'">
        <!-- Строковое поле -->
        <filter-text [filter]="filterColumn[column.field]" [form]="getControl(column.field)"></filter-text>
      </ng-container>
      <ng-container *ngIf="filterColumn[column.field].type === 'date'">
        <!-- Дата -->
        <filter-date [filter]="filterColumn[column.field]" [form]="getControl(column.field)"></filter-date>
      </ng-container>
      <ng-container *ngIf="filterColumn[column.field].type === 'select'">
        <!-- Список -->
        <filter-select [filter]="filterColumn[column.field]" [form]="getControl(column.field)"></filter-select>
      </ng-container>
      <ng-container *ngIf="filterColumn[column.field].type === 'multiselect'">
        <!-- Множестенный список -->
        <filter-multiselect
          [filter]="filterColumn[column.field]"
          [form]="getControl(column.field)"></filter-multiselect>
      </ng-container>
      <ng-container *ngIf="filterColumn[column.field].type === 'checkbox'">
        <!-- Чекбок -->
        <filter-checkbox [filter]="filterColumn[column.field]" [form]="getControl(column.field)"></filter-checkbox>
      </ng-container>
    </ng-container>
  </td>
</ng-container>
<td
  *ngIf="hasActions"
  class="ant-table-cell-fix-right-first ant-table-cell ant-table-cell-fix-right action-column"
  style="position: sticky; right: 0px"></td>
