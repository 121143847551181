import { Injectable } from '@angular/core';
import { LocalStorage, SessionStorage } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  /**
   * Окружение
   */
  @SessionStorage('context')
  public context: any;

  /**
   * последняя страница
   */
  @SessionStorage('urlRedirect')
  public urlRedirect?: string;

  constructor() {}

  /**
   * Получить Url для редиректа
   */
  getRedirectURL(defaultUrl: string = '') {
    return this.urlRedirect ? this.urlRedirect : defaultUrl;
  }

  /**
   * Сохранить Url для редиректа
   */
  setRedirectURL(url?: string) {
    this.urlRedirect = url || window.location.pathname;
  }
}
