<ul *ngIf="menuList$" nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
  <ng-container *ngFor="let menu of menuList$ | async">
    <ng-container *ngIf="!menu.submenu; else hasSubMenu">
      <li
        nz-menu-item
        nzMatchRouter
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed ? menu.title : ''">
        <a [routerLink]="menu.url">
          <i nz-icon [nzType]="menu.icon"></i> <span>{{ menu.title }}</span>
        </a>
      </li>
    </ng-container>
    <ng-template #hasSubMenu>
      <li nz-submenu [nzTitle]="menu.title" [nzIcon]="menu.icon" [nzOpen]="isActiveSubmenu(menu)">
        <ul>
          <ng-container *ngFor="let submenu of menu.submenu">
            <li nz-menu-item nzMatchRouter>
              <a [routerLink]="submenu.url">
                <i nz-icon [nzType]="submenu.icon"></i> <span>{{ submenu.title }}</span>
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
    </ng-template>
  </ng-container>
</ul>
