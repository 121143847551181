<nz-input-group [nzSuffix]="suffixIconSearch">
  <input
    nz-input
    autocomplete="off"
    [mask]="mask"
    [showMaskTyped]="realOpenState"
    [dropSpecialCharacters]="false"
    [formControl]="formControlEnter"
    [attr.placeholder]="nzPlaceholder"
    (focus)="onFocus()"
    (blur)="onBlur()" />
</nz-input-group>
<ng-template #suffixIconSearch>
  <span class="ant-picker-suffix" [ngClass]="{ 'ant-picker-suffix_clear': showClear() }">
    <span>
      <i nz-icon nzType="calendar" nzTheme="outline" class="ant-datepicker-icon"></i>
    </span>
    <span (click)="clear($event)" class="ant-datepicker-clear">
      <i nz-icon nzType="close-circle" nzTheme="fill"></i>
    </span>
  </span>
</ng-template>
<ng-template
  cdkConnectedOverlay
  nzConnectedOverlay
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="realOpenState"
  [cdkConnectedOverlayPositions]="overlayPositions"
  [cdkConnectedOverlayTransformOriginOn]="'.ant-picker-wrapper'"
  (detach)="close()">
  <nz-date-picker nzInline [nzFormat]="nzFormat" [formControl]="formControl"></nz-date-picker>
</ng-template>
