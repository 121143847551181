import { IJournalColumn } from './journal-column.type';
import { IJournalFilter, IJournalFilterParam } from './journal-filter.type';
import { IJournalQuerySort } from 'src/app/lib/journal/types/journal-query.type';

export interface IJournal {
  // колонки журнала
  columns: IJournalColumn[];
  // Дополнительные колонки, только для данных
  additionalColumns?: string[];
  // доступные фильтры журнала
  filters?: IJournalFilter[];
}

export interface IJournalManage {
  columns?: IJournalColumn[];
  filters?: IJournalFilterParam[];
  filtersInline?: IJournalFilterParam[];
  isFilterVisible: boolean;
  isOptionVisible: boolean;
  perPage?: number;
}

export interface IJournalStorage {
  columns?: string[];
  filters?: IJournalFilterParam[];
  filtersInline?: IJournalFilterParam[];
  sort: IJournalQuerySort[];
  isFilterVisible: boolean;
  isOptionVisible: boolean;
  perPage?: number;
}

export type JournalRowType = { [key: string]: any } | any;

export type JournalCheckedType = 'none' | 'checkbox' | 'radio';
