export const ROUTE_AUTH = 'auth';
export const ROUTE_DIAGNOSTICS = 'diagnostics';
export const ROUTE_MANAGEMENT = 'management';
export const ROUTE_SYSTEM = 'system';
export const ROUTE_USER = 'user';
export const ROUTE_BOARD = 'board';
export const ROUTE_CRATE = 'crate';
export const ROUTE_CRATE_MODULE = 'crate-module';
export const ROUTE_REGISTER = 'register';
export const ROUTE_CHANNEL = 'channel';
export const ROUTE_VIRTUAL_MODULE = 'virtual-module';
export const ROUTE_VIRTUAL_CHANNEL = 'virtual-channel';
export const ROUTE_TELEMETRY_CONFIGURATION = 'telemetry-configuration';
export const ROUTE_SCREEN_CONFIGURATION = 'screen-configuration';
export const ROUTE_SCENARIO = 'scenario';
export const ROUTE_EXPERIMENT = 'experiment';
export const ROUTE_EXPERIMENT_PLAN = 'experiment-plan';
export const ROUTE_DIAGRAMS = 'diagrams';
export const ROUTE_SCREEN = 'screen';
export const ROUTE_HELP = 'help';

export const ROUTE_CRUD_EDIT = 'edit';
export const ROUTE_CRUD_CREATE = 'create';

export const ROUTE_BREADCRUMB_CREATE = 'Создать';
export const ROUTE_BREADCRUMB_EDIT = 'Редактировать';
export const ROUTE_BREADCRUMB_VIEW = 'Просмотр';
