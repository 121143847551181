import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IMenu } from 'src/app/shared/types/menu.type';

@Component({
  selector: 'sidebar-menu',
  templateUrl: 'sidebar-menu.component.html',
  styleUrls: ['sidebar-menu.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarMenuComponent {
  /**
   * Список меню
   */
  @Input() menuList$?: Observable<IMenu[]>;
  /**
   * Состояние свернуто
   */
  @Input() isCollapsed = false;

  constructor(private route: Router) {}

  isActiveSubmenu(menu: IMenu) {
    return !!menu.submenu?.find((m) => this.route.url.startsWith(`/${m.url}`));
  }
}
