import { Component, EventEmitter, Input, Output } from '@angular/core';
import { get } from 'lodash-es';

@Component({
  selector: 'nz-transfer-select-sorting',
  templateUrl: './nz-transfer-select-sorting.component.html',
  styleUrls: ['./nz-transfer-select-sorting.component.less'],
})
export class NzTransferSelectSortingComponent {
  @Input({ required: true }) availableList: any[] = [];
  @Input({ required: true }) selectedList: any[] = [];
  @Input({ required: true }) key = 'key';

  @Input() availableTitle = 'Доступные';
  @Input() selectedTitle = 'Выбранные';

  @Output() selectedListChange: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input() valid = false;
  @Input() invalid = false;
  @Input() disabled = false;

  available: any[] = [];
  selected: any[] = [];

  private emit() {
    this.selectedListChange.emit(this.selectedList);
  }

  isSelectedItem(item: any) {
    return this.selectedList.findIndex((e) => get(e, this.key) === get(item, this.key)) !== -1;
  }

  removeSelected() {
    this.selectedList = this.selectedList.filter((item) => !this.selected.includes(item));
    this.selected = [];
    this.emit();
  }

  toSelected() {
    this.selectedList = [...this.selectedList];
    this.selectedList.push(...this.available);
    this.available = [];
    this.emit();
  }

  up() {
    const indexes = this.selected.map((s) => this.selectedList.indexOf(s));

    const moveUp = [];
    let minIndex = 0;
    for (const item of indexes) {
      if (item <= minIndex) {
        minIndex = item + 1;
      } else {
        moveUp.push(item);
      }
    }

    const newIndexes = this.selectedList.map((_, i) => i);
    moveUp.forEach((i) => {
      const tmp = newIndexes[i];
      newIndexes[i] = newIndexes[i - 1];
      newIndexes[i - 1] = tmp;
    });

    this.selectedList = newIndexes.map((i) => this.selectedList[i]);
    this.emit();
  }

  down() {
    const indexes = this.selected.map((s) => this.selectedList.indexOf(s));

    const moveDown = [];
    let maxIndex = this.selectedList.length - 1;
    for (const item of indexes.reverse()) {
      if (item === maxIndex) {
        maxIndex = item - 1;
      } else {
        moveDown.push(item);
      }
    }

    const newIndexes = this.selectedList.map((_, i) => i);
    moveDown.forEach((i) => {
      const tmp = newIndexes[i];
      newIndexes[i] = newIndexes[i + 1];
      newIndexes[i + 1] = tmp;
    });

    this.selectedList = newIndexes.map((i) => this.selectedList[i]);
    this.emit();
  }

  moveTop() {
    const indexes = this.selected.map((s) => this.selectedList.indexOf(s));

    const moveUp = [];
    let minIndex = 0;
    for (const item of indexes) {
      if (item <= minIndex) {
        minIndex = item + 1;
      } else {
        moveUp.push(item);
      }
    }

    const newIndexes = this.selectedList.map((_, i) => i);
    moveUp.forEach((i) => {
      for (let j = i; j > minIndex; j--) {
        const tmp = newIndexes[j];
        newIndexes[j] = newIndexes[j - 1];
        newIndexes[j - 1] = tmp;
      }
      minIndex++;
    });

    this.selectedList = newIndexes.map((i) => this.selectedList[i]);
    this.emit();
  }

  moveBottom() {
    const indexes = this.selected.map((s) => this.selectedList.indexOf(s));

    const moveDown = [];
    let maxIndex = this.selectedList.length - 1;
    for (const item of indexes.reverse()) {
      if (item === maxIndex) {
        maxIndex = item - 1;
      } else {
        moveDown.push(item);
      }
    }

    const newIndexes = this.selectedList.map((_, i) => i);
    moveDown.forEach((i) => {
      for (let j = i; j < maxIndex; j++) {
        const tmp = newIndexes[j];
        newIndexes[j] = newIndexes[j + 1];
        newIndexes[j + 1] = tmp;
      }
      maxIndex--;
    });

    this.selectedList = newIndexes.map((i) => this.selectedList[i]);
    this.emit();
  }
}
