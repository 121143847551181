import { Component, OnInit, ViewChild } from '@angular/core';
import { FilterBaseComponent } from '../base/filter-base.component';
import { FILTER_CONDITION_REFERENCE } from '../base/filter-condition-reference.const';
import { JournalComponent } from '../../journal/journal.component';
import { IJournalFilterDataJournal } from '../../types/journal-filter.type';
import plural from 'src/app/lib/plural';
import { get, isUndefined, set } from 'lodash-es';

@Component({
  selector: 'filter-reference',
  templateUrl: './filter-reference.component.html',
  styleUrls: ['../base/filter-base.components.less', './filter-reference.component.less'],
})
export class FilterReferenceComponent extends FilterBaseComponent implements OnInit {
  /**
   * окно с журналом
   */
  isVisibleModal = false;
  /**
   * Параметры
   */
  params!: IJournalFilterDataJournal;
  /**
   * Результат выбора
   */
  referenceValue = '';

  @ViewChild('journal') journal?: JournalComponent;

  constructor() {
    super();
  }

  ngOnInit() {
    if (!this.filter) return;

    if (!this.filter.inline) {
      this.filterCondition = this.filter.condition || FILTER_CONDITION_REFERENCE;
    }

    this.params = this.filter.params as IJournalFilterDataJournal;
    // по умолчанию используется primaryKey
    if (isUndefined(this.params.bindValue)) {
      this.params.bindValue = 'id';
    }

    this.updateReferenceValue(this.form.get('value')?.value);

    this.init();
  }

  handleOpenModal() {
    this.isVisibleModal = true;
    return false;
  }

  onAfterOpen() {
    const values = this.form.get('value')?.value;
    if (values) {
      this.journal?.setOfChecked$.next(
        new Set(
          values.map((value: any) => {
            const key = this.filter?.params?.bindValue;
            if (key) {
              return set({}, key, value);
            }

            return value;
          }),
        ),
      );
    }
  }

  handleCancel() {
    this.isVisibleModal = false;
  }

  handleOk() {
    const values = [...(this.journal?.setOfChecked$.value as any)];
    if (!values.length) {
      return;
    }

    this.isVisibleModal = false;
    this.form.get('value')?.setValue(values.map((value) => this.getValue(value)));
    this.updateReferenceValue(values);
  }

  getValue(item: object): object | number | string {
    return this.filter?.params?.bindValue ? get(item, this.filter.params.bindValue) : item;
  }

  private updateReferenceValue(values: (string | number)[]) {
    const size = values?.length;

    this.referenceValue = size
      ? `${plural(size, 'Выбран', 'Выбрано', 'Выбрано')} ${size} ${plural(size, 'элемент', 'элемента', 'элементов')}`
      : '';
  }
}
