import { filter, Observable, Subject } from 'rxjs';
import { WebSocketConfig } from './websocket-config.interface';
import { WebSocketSendMode } from './websocket.enum';
import { map } from 'rxjs/operators';
import { isNil, negate } from 'lodash-es';
import { IWebsocketData } from 'src/app/shared/types/response.type';
import { environment } from 'src/environments/environment';

const DEFAULT_CONFIG = {
  initialTimeout: 200,
  maxTimeout: 3600000,
  reconnectIfNotNormalClose: false,
};

export class $WebSocket<T> {
  constructor(
    private url: string,
    protocols?: Array<string>,
    config?: WebSocketConfig,
    binaryType?: BinaryType,
  ) {
    const match = new RegExp('wss?://').test(url);
    if (!match) {
      throw new Error('Invalid url provided');
    }
    this.protocols = protocols || [];
    this.config = Object.assign(DEFAULT_CONFIG, config);
    this.binaryType = binaryType || 'blob';
    this.dataStream = new Subject();
    this.errorMessages = new Subject();
    this.connect(true);
  }

  private static Helpers = class {
    static isPresent(obj: any): boolean {
      return obj !== undefined && obj !== null;
    }

    static isString(obj: any): boolean {
      return typeof obj === 'string';
    }

    static isArray(obj: any): boolean {
      return Array.isArray(obj);
    }

    static isFunction(obj: any): boolean {
      return typeof obj === 'function';
    }
  };

  private firstConnect = true;
  private reconnectAttempts = 0;
  private sendQueue: any[] = [];
  private onOpenCallbacks: any[] = [];
  private onMessageCallbacks: any[] = [];
  private onErrorCallbacks: any[] = [];
  private onCloseCallbacks: any[] = [];
  private readyStateConstants = {
    UNINITIALIZED: -1,
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3,
    RECONNECT_ABORTED: 4,
  };
  private normalCloseCode = 1000;
  private reconnectableStatusCodes = [4000];
  private socket!: WebSocket;
  private dataStream: Subject<MessageEvent>;
  private errorMessages: Subject<object>;
  private internalConnectionState!: number;
  private protocols: Array<string>;
  private config: WebSocketConfig;
  private binaryType: BinaryType;

  private send4Mode: WebSocketSendMode = WebSocketSendMode.Direct;

  connect(force = false) {
    this.log(`WebSocket ${this.url} connecting...`);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    if (force || !this.socket || this.socket.readyState !== this.readyStateConstants.OPEN) {
      self.socket = this.protocols ? new WebSocket(this.url, this.protocols) : new WebSocket(this.url);
      self.socket.binaryType = self.binaryType;

      self.socket.onopen = (ev: Event) => {
        this.log('onOpen', ev);
        this.onOpenHandler(ev);
        this.firstConnect = false;
      };
      self.socket.onmessage = (ev: MessageEvent) => {
        self.onMessageHandler(ev);
        this.dataStream.next(ev);
      };
      this.socket.onclose = (ev: CloseEvent) => {
        this.log('onClose', ev);
        self.onCloseHandler(ev);
      };

      this.socket.onerror = (ev: Event) => {
        this.log('onError', ev);
        self.onErrorHandler(ev);
        this.errorMessages.next(ev);
      };
    }
  }

  getErrorStream(): Subject<any> {
    return this.errorMessages;
  }

  /**
   * Run in Block Mode
   * Return true when can send and false in socket closed
   */
  send4Direct(data: any, binary?: boolean): boolean {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    if (
      this.getReadyState() !== this.readyStateConstants.OPEN &&
      this.getReadyState() !== this.readyStateConstants.CONNECTING
    ) {
      this.connect();
    }
    self.sendQueue.push({ message: data, binary: binary });
    if (self.socket.readyState === self.readyStateConstants.OPEN) {
      self.fireQueue();
      return true;
    } else {
      return false;
    }
  }

  /**
   * Return Promise
   * When can Send will resolve Promise
   * When Socket closed will reject Promise
   */
  send4Promise(data: any, binary?: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.send4Direct(data, binary)) {
        return resolve(data);
      } else {
        return reject(Error('Socket connection has been closed'));
      }
    });
  }

  /**
   * Return cold Observable
   * When can Send will complete observer
   * When Socket closed will error observer
   */
  send4Observable(data: any, binary?: boolean): Observable<any> {
    return new Observable((observer) => {
      if (this.send4Direct(data, binary)) {
        return observer.complete();
      } else {
        if (this.firstConnect) {
          return observer.complete();
        } else {
          return observer.error('Socket connection has been closed');
        }
      }
    });
  }

  /**
   * Set send(data) function return mode
   */
  setSend4Mode(mode: WebSocketSendMode): void {
    this.send4Mode = mode;
  }

  /**
   * Use {mode} mode to send {data} data
   * If no specify, Default SendMode is Observable mode
   */
  send(data: any, mode?: WebSocketSendMode, binary?: boolean): any {
    switch (typeof mode !== 'undefined' ? mode : this.send4Mode) {
      case WebSocketSendMode.Direct:
        return this.send4Direct(data, binary);
      case WebSocketSendMode.Promise:
        return this.send4Promise(data, binary);
      case WebSocketSendMode.Observable:
        return this.send4Observable(data, binary);
      default:
        throw Error('WebSocketSendMode Error.');
    }
  }

  getDataStream(): Observable<IWebsocketData<T>> {
    return this.dataStream.pipe(
      map((msg: MessageEvent) => {
        try {
          return {
            status: 'success',
            data: JSON.parse(msg.data),
          };
        } catch (e) {
          return {
            status: 'error',
            message: 'Некорректное сообщение: ' + msg.data,
          };
        }
      }),
      filter(negate(isNil)),
    );
  }

  onOpenHandler(event: Event) {
    this.reconnectAttempts = 0;
    this.notifyOpenCallbacks(event);
    this.fireQueue();
  }

  notifyOpenCallbacks(event: Event) {
    this.log(`WebSocket ${this.url} connect success`);
    for (let i = 0; i < this.onOpenCallbacks.length; i++) {
      this.onOpenCallbacks[i].call(this, event);
    }
  }

  fireQueue() {
    while (this.sendQueue.length && this.socket.readyState === this.readyStateConstants.OPEN) {
      const data = this.sendQueue.shift();

      this.log('fireQueue: ', data);
      if (data.binary) {
        this.socket.send(data.message);
      } else {
        this.socket.send($WebSocket.Helpers.isString(data.message) ? data.message : JSON.stringify(data.message));
      }
    }
  }

  notifyCloseCallbacks(event: Event) {
    for (let i = 0; i < this.onCloseCallbacks.length; i++) {
      this.onCloseCallbacks[i].call(this, event);
    }
  }

  notifyErrorCallbacks(event: Event) {
    for (let i = 0; i < this.onErrorCallbacks.length; i++) {
      this.onErrorCallbacks[i].call(this, event);
    }
  }

  onOpen(cb: any) {
    this.onOpenCallbacks.push(cb);
    return this;
  }
  onClose(cb: any) {
    this.onCloseCallbacks.push(cb);
    return this;
  }

  onError(cb: any) {
    this.onErrorCallbacks.push(cb);
    return this;
  }
  onMessage(callback: () => unknown, options?: any) {
    if (!$WebSocket.Helpers.isFunction(callback)) {
      throw new Error('Callback must be a function');
    }

    this.onMessageCallbacks.push({
      fn: callback,
      pattern: options ? options.filter : undefined,
      autoApply: options ? options.autoApply : true,
    });
    return this;
  }

  onMessageHandler(message: MessageEvent) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    let currentCallback;
    for (let i = 0; i < self.onMessageCallbacks.length; i++) {
      currentCallback = self.onMessageCallbacks[i];
      currentCallback.fn.apply(self, [message]);
    }
  }
  onCloseHandler(event: CloseEvent) {
    this.notifyCloseCallbacks(event);

    if (
      (this.config.reconnectIfNotNormalClose && event.code !== this.normalCloseCode) ||
      this.reconnectableStatusCodes.indexOf(event.code) > -1
    ) {
      this.reconnect();
    } else {
      this.sendQueue = [];
      this.dataStream.complete();
    }
  }
  onErrorHandler(event: Event) {
    this.notifyErrorCallbacks(event);
  }
  reconnect() {
    this.close(true, true);
    const backoffDelay = this.getBackoffDelay(++this.reconnectAttempts);
    this.log('Reconnecting in ' + backoffDelay + ' ms');
    setTimeout(() => {
      if (this.config.reconnectIfNotNormalClose) {
        this.connect();
      }
    }, backoffDelay);
    return this;
  }

  close(force = false, keepReconnectIfNotNormalClose?: boolean) {
    if (!keepReconnectIfNotNormalClose) {
      this.config.reconnectIfNotNormalClose = false;
    }

    if (force || !this.socket.bufferedAmount) {
      this.socket.close(this.normalCloseCode);
    }
    return this;
  }
  // Exponential Backoff Formula by Prof. Douglas Thain
  // http://dthain.blogspot.co.uk/2009/02/exponential-backoff-in-distributed.html
  getBackoffDelay(attempt: number) {
    const R = Math.random() + 1;
    const T = this.config.initialTimeout || DEFAULT_CONFIG.initialTimeout;
    const F = 2;
    const N = attempt;
    const M = this.config.maxTimeout || DEFAULT_CONFIG.maxTimeout;

    return Math.floor(Math.min(R * T * Math.pow(F, N), M));
  }
  setInternalState(state: number) {
    if (Math.floor(state) !== state || state < 0 || state > 4) {
      throw new Error('state must be an integer between 0 and 4, got: ' + state);
    }

    this.internalConnectionState = state;
  }

  getReadyState() {
    if (this.socket == null) {
      return this.readyStateConstants.UNINITIALIZED;
    }
    return this.internalConnectionState || this.socket.readyState;
  }

  log(message: any, ...data: any[]) {
    if (environment.logLevel >= 5) {
      console.log(message, ...data);
    }
  }
}
