import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { IJournal, IJournalManage } from '../types/journal.type';
import { IJournalFilter, IJournalFilterParam } from '../types/journal-filter.type';
import { IJournalColumn } from '../types/journal-column.type';
import { cloneColumns } from '../utils/helpers';
import { JournalFilterComponent } from '../journal-filter/journal-filter.component';
import { JournalService } from '../services/journal.service';
import { IJournalQueryFilter } from 'src/app/lib/journal/types/journal-query.type';

@Component({
  selector: 'journal-manage',
  templateUrl: './journal-manage.component.html',
  styleUrls: ['./journal-manage.component.less'],
})
export class JournalManageComponent implements OnInit {
  /**
   * Конфигурация журнала
   */
  @Input({ required: true }) config!: IJournal;
  /**
   * Фильтры
   */
  @Input({ required: true }) filtersParam!: IJournalFilterParam[];
  /**
   * Колонки
   */
  @Input({ required: true }) columns!: IJournalColumn[];
  /**
   * Дополнительные параметры запроса
   */
  @Input({ required: true }) customParams!: IJournalQueryFilter[] | null;
  /**
   * Загрузка данных
   */
  @Input({ required: true }) isLoadingData$!: Observable<boolean>;
  /**
   * Видимость фильтра
   */
  @Input() isFilterVisible!: boolean;
  /**
   * Видимость опций
   */
  @Input() isOptionVisible!: boolean;
  /**
   * Фильтр в виде формы
   */
  @Input({ required: true }) isFormFilter = true;
  /**
   * Обновить
   */
  @Output() update: EventEmitter<IJournalManage> = new EventEmitter();

  @ViewChild(JournalFilterComponent) journalFilter?: JournalFilterComponent;

  availableFilter: IJournalFilter[] = [];

  constructor(private journalService: JournalService) {}

  ngOnInit() {
    this.availableFilter = (this.config?.filters || [])
      .filter((filter) => !filter.inline)
      .filter((filter) => !this.customParams?.find((param) => param.field === filter.field));
  }

  /**
   * Применить фильтр
   */
  onApply() {
    if (this.availableFilter.length === 0) {
      this.emit(this.columns, []);
      return;
    }

    const filterParam = this.journalFilter?.doFilter();
    if (filterParam) {
      this.emit(this.columns, filterParam);
    }
  }

  /**
   * Изменено раскрытие
   */
  onChangeActive() {
    this.update.emit({
      isFilterVisible: this.isFilterVisible,
      isOptionVisible: this.isOptionVisible,
    });
  }

  /**
   * Вернуть
   */
  onReset() {
    if (!this.config) return;

    this.columns = cloneColumns(this.config.columns).filter((col) => col.visible);
    this.filtersParam = this.availableFilter
      .filter((filter) => filter.visible)
      .map((filter) => this.journalService.getParamFilter(filter, !!filter.enable));
    this.emit(this.columns, this.filtersParam);
  }

  /**
   * Формируем событие изменения
   */
  private emit(columns: IJournalColumn[], filterParam: IJournalFilterParam[]) {
    this.update.emit({
      columns: columns,
      filters: filterParam,
      isFilterVisible: this.isFilterVisible,
      isOptionVisible: this.isOptionVisible,
    });
  }
}
