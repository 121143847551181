import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';
import { startWith } from 'rxjs/operators';
import { format, formatISO, parse, parseISO } from 'date-fns';
import { CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';
import { FormControlValueAccessorAdapter } from '../form-control-value-accessor.adapter';

@Component({
  selector: 'nz-date-picker-mask',
  templateUrl: './nz-date-picker-mask.component.html',
  styleUrls: ['./nz-date-picker-mask.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: NzDatePickerMaskComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: NzDatePickerMaskComponent,
      multi: true,
    },
  ],
})
export class NzDatePickerMaskComponent extends FormControlValueAccessorAdapter implements OnInit {
  /**
   * Маска
   */
  @Input() mask = '00.00.0000';
  /**
   * Подсказка
   */
  @Input() nzPlaceholder = 'Выберите дату';
  /**
   * Формат
   */
  @Input() nzFormat = 'dd.MM.yyyy';
  /**
   * Заблокированно
   */
  @Input() nzDisabled = false;
  /**
   * Очистить
   */
  @Input() nzAllowClear = true;

  readonly formControl = new FormControl(null);
  formControlEnter: FormControl = new FormControl(null);

  realOpenState = false;

  origin: CdkOverlayOrigin;
  overlayPositions: ConnectionPositionPair[] = [
    {
      offsetY: 2,
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
    {
      offsetY: -2,
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
    },
    {
      offsetY: 2,
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
    {
      offsetY: -2,
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
    },
  ] as ConnectionPositionPair[];

  constructor(private elementRef: ElementRef) {
    super();

    this.origin = new CdkOverlayOrigin(this.elementRef);
  }

  ngOnInit() {
    this.formControl.valueChanges
      .pipe(untilDestroyed(this), startWith(this.formControl.value))
      .subscribe((value: any) => {
        if (value) {
          if (typeof value === 'string') {
            value = parseISO(value);
          }
          this.formControlEnter.setValue(format(value, this.nzFormat), { emitEvent: false });
        } else {
          this.formControlEnter.setValue(null, { emitEvent: false });
        }

        if (this.formControl.disabled && !this.formControlEnter.disabled) {
          this.formControlEnter.disable();
        }

        if (this.formControl.enabled && !this.formControlEnter.enabled) {
          this.formControlEnter.enable();
        }
      });

    this.formControlEnter.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value) {
        if (new RegExp(/^(\d{2})\.(\d{2})\.(\d{4})$/).test(value)) {
          this.updateDateModel(value);
        }
      } else {
        const previousDate = this.formControl?.value;
        if (previousDate) {
          this.formControl.setValue(null);
        }
      }
    });
  }

  onFocus() {
    this.realOpenState = true;
  }

  onBlur() {
    this.realOpenState = false;

    if (!this.updateDateModel(this.formControlEnter?.value)) {
      this.formControlEnter.setValue(null);
    }
  }

  showClear(): boolean {
    return !this.nzDisabled && this.formControlEnter?.value && this.nzAllowClear;
  }

  clear(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.formControlEnter.setValue(null);
  }

  close() {
    this.realOpenState = false;
  }

  private updateDateModel(value: string): boolean {
    if (value) {
      const date: Date = parse(value, this.nzFormat, new Date());
      if (this.isValidDate(date)) {
        const previousDate = this.formatDateISO(this.formControl?.value);
        const currentDate = formatISO(date);

        if (currentDate !== previousDate) {
          this.formControl.setValue(date as any);
        }
        return true;
      }
    } else if (!this.formControl?.value) {
      return true;
    }

    return false;
  }

  private formatDateISO(value: Date | string | null) {
    if (value) {
      if (typeof value === 'object') {
        return formatISO(value as Date);
      } else {
        return formatISO(parseISO(value));
      }
    }

    return null;
  }

  private isValidDate(d: unknown) {
    return d instanceof Date && !isNaN(d.getTime());
  }
}
