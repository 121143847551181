export interface IMocks {
  url: string;
  params?: any;
  // по-умолчанию 200
  httpCode?: number;
  json?: string;
}

export const mocks: IMocks[] = [
  // {
  //   url: '/auth/signIn/basic',
  //   json: 'login',
  //   params: {
  //     username: 'admin',
  //     password: 'admin',
  //   },
  // },
  // {
  //   url: '/auth/users/search',
  //   json: 'user-search',
  // },
  // {
  //   url: '/ws/board',
  //   json: 'board',
  // },
  // {
  //   url: '/boards/search',
  //   json: 'board-search',
  // },
  // {
  //   url: '/crate/search',
  //   json: 'crate-search',
  // },
  // {
  //   url: '/crateModules/search',
  //   json: 'crateModules-search',
  // },
  // {
  //   url: '/screenConfiguration/search',
  //   json: 'screenConfiguration-search',
  // },
  // {
  //   url: '/channelConfig/search',
  //   json: 'channelConfig-search',
  // },
  // {
  //   url: '/telemetryConfiguration/search',
  //   json: 'telemetryConfiguration-search',
  // },
  // {
  //   url: '/diagrams',
  //   json: 'diagrams',
  // },
  // {
  //   url: '/channels/search',
  //   json: 'channel-search',
  // },
  // {
  //   url: '/scenarios/search',
  //   json: 'scenario-search',
  // },
  // {
  //   url: '/experiment/search',
  //   json: 'experiment-search',
  // },
  // {
  //   url: '/experiment/1',
  //   json: 'experiment',
  // },
  // {
  //   url: '/system/composition',
  //   json: 'diagnostics',
  // },
];
