<ng-container [formGroup]="form" *ngIf="form">
  <filter-condition
    *ngIf="filterCondition.length > 0"
    [form]="form"
    [label]="filter?.label"
    [filterCondition]="filterCondition">
    <nz-switch formControlName="value" nzCheckedChildren="Да" nzUnCheckedChildren="Нет"></nz-switch>
  </filter-condition>

  <ng-container *ngIf="filterCondition.length === 0">
    <nz-switch formControlName="value" nzCheckedChildren="Да" nzUnCheckedChildren="Нет"></nz-switch>
  </ng-container>
</ng-container>
