<span [ngClass]="{ 'ant-radio': true, 'ant-radio-checked': checked }">
  <input
    class="ant-radio-input"
    type="radio"
    [name]="name"
    [value]="value"
    ngModel
    [checked]="checked"
    (ngModelChange)="changeState.emit(!checked)" />
  <span class="ant-radio-inner"></span>
</span>
