import { IMenu } from '../shared/types/menu.type';
import { Role } from '../shared/consts/roles.enum';
import {
  ROUTE_BOARD,
  ROUTE_CRATE_MODULE,
  ROUTE_REGISTER,
  ROUTE_CHANNEL,
  ROUTE_CRATE,
  ROUTE_DIAGNOSTICS,
  ROUTE_DIAGRAMS,
  ROUTE_EXPERIMENT,
  ROUTE_EXPERIMENT_PLAN,
  ROUTE_SCREEN_CONFIGURATION,
  ROUTE_TELEMETRY_CONFIGURATION,
  ROUTE_USER,
  ROUTE_SCENARIO,
  ROUTE_MANAGEMENT,
  ROUTE_VIRTUAL_MODULE,
  ROUTE_VIRTUAL_CHANNEL,
  ROUTE_SYSTEM,
} from '../shared/consts/routes.const';

export const MENU: IMenu[] = [
  // {
  //   title: 'Система',
  //   icon: 'laptop',
  //   submenu: [
  //     {
  //       title: 'Состояние',
  //       url: ROUTE_DIAGNOSTICS,
  //       icon: 'dashboard',
  //     },
  //     {
  //       title: 'Выбор объекта контроля',
  //       url: ROUTE_MANAGEMENT,
  //       icon: 'interaction',
  //     },
  //   ],
  // },
  {
    title: 'Система',
    url: ROUTE_SYSTEM,
    icon: 'laptop',
  },
  {
    title: 'Пользователи',
    url: ROUTE_USER,
    icon: 'user',
    roles: [Role.ADMIN],
  },
  {
    title: 'Объекты управления',
    icon: 'global',
    submenu: [
      {
        title: 'Крейты',
        url: ROUTE_CRATE,
        icon: 'hdd',
        roles: [Role.SOFTWARE_ENGINEER, Role.ADMIN],
      },
      {
        title: 'Модули',
        url: ROUTE_CRATE_MODULE,
        icon: 'mobile',
        roles: [Role.SOFTWARE_ENGINEER, Role.ADMIN],
      },
      {
        title: 'Регистры',
        url: ROUTE_REGISTER,
        icon: 'snippets',
        roles: [Role.SOFTWARE_ENGINEER, Role.ADMIN],
      },
    ],
  },
  {
    title: 'Объекты контроля',
    url: ROUTE_BOARD,
    icon: 'build',
    roles: [Role.SOFTWARE_ENGINEER, Role.ADMIN],
  },
  {
    title: 'Каналы',
    url: ROUTE_CHANNEL,
    icon: 'node-expand',
    roles: [Role.SOFTWARE_ENGINEER, Role.ADMIN],
  },
  {
    title: 'Виртуальные модули',
    url: ROUTE_VIRTUAL_MODULE,
    icon: 'folder-view',
    roles: [Role.SOFTWARE_ENGINEER, Role.ADMIN],
  },
  {
    title: 'Виртуальные каналы',
    url: ROUTE_VIRTUAL_CHANNEL,
    icon: 'node-index',
    roles: [Role.SOFTWARE_ENGINEER, Role.ADMIN],
  },
  {
    title: 'Конфигурация телеметрии',
    url: ROUTE_TELEMETRY_CONFIGURATION,
    icon: 'box-plot',
    roles: [Role.SOFTWARE_ENGINEER, Role.ADMIN],
  },
  {
    title: 'Конфигурация экранов',
    url: ROUTE_SCREEN_CONFIGURATION,
    icon: 'control',
    roles: [Role.SOFTWARE_ENGINEER, Role.ADMIN],
  },
  {
    title: 'Сценарии',
    url: ROUTE_SCENARIO,
    icon: 'reconciliation',
    roles: [Role.SOFTWARE_ENGINEER, Role.ADMIN],
  },
  {
    title: 'План испытаний',
    url: ROUTE_EXPERIMENT_PLAN,
    icon: 'partition',
    roles: [Role.SOFTWARE_ENGINEER, Role.ADMIN, Role.OPERATOR],
  },
  {
    title: 'Журнал испытаний',
    url: ROUTE_EXPERIMENT,
    icon: 'experiment',
    roles: [Role.SOFTWARE_ENGINEER, Role.ADMIN, Role.OPERATOR],
  },
  {
    title: 'Телеметрия',
    url: ROUTE_DIAGRAMS,
    icon: 'area-chart',
    roles: [Role.SOFTWARE_ENGINEER, Role.ADMIN, Role.OPERATOR],
  },
];
