import { Injectable } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import jwt_decode from 'jwt-decode';
import { get } from 'lodash-es';
import { USER_ROLES } from '../../consts/roles.enum';
import { IAuthLogin, IAuthToken } from '../../types/auth.type';
import { IProfile } from '../../types/profile.type';
import { AuthService } from 'src/app/pages/auth/services/auth.service';
import { findMainRole } from 'src/app/shared/helpers/role';
import { NzModalService } from 'ng-zorro-antd/modal';
import { takeUntil } from 'rxjs/operators';
import { MESSAGE_ERROR_TOKEN_EXPIRED } from 'src/app/shared/consts/messages.const';
import { environment } from 'src/environments/environment';
import { Logger } from 'src/app/shared/services/logger/logger.service';
import { ROUTE_AUTH } from 'src/app/shared/consts/routes.const';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  /**
   * Токен
   */
  @LocalStorage('accessToken')
  public accessToken!: string;
  /**
   * Рефреш
   */
  @LocalStorage('refreshToken')
  public refreshToken!: string;
  /**
   * Данные профиля
   */
  public profile?: IProfile;
  /**
   * Подписка на токен
   */
  private tokenExpired = new Subject<string | null>();

  constructor(
    private modal: NzModalService,
    private authService: AuthService,
    private logger: Logger,
    private storage: LocalStorageService,
    private router: Router,
  ) {
    this.storage
      .observe('accessToken')
      .subscribe((accessToken) => {
        if (!accessToken) {
          this.router.navigate([`/${ROUTE_AUTH}/login`]);
        }
      });
  }

  /**
   * Проверка на авторизован?
   */
  isLoggedIn() {
    return !!this.accessToken;
  }

  /**
   * Выйти
   */
  logout() {
    this.authService.logout().subscribe();
    this.profile = undefined;
    this.accessToken = '';
    this.refreshToken = '';
  }

  /**
   * Обновить токены
   * @param data - данные из аутентификации
   */
  setToken(data: IAuthLogin) {
    this.accessToken = data.accessToken;
    this.refreshToken = data.refreshToken;
    this.getUserInfo();
  }

  /**
   * Формируем информацию о профиле на основе токена
   */
  getUserInfo() {
    if (!this.accessToken) {
      this.profile = undefined;
      return;
    }

    const token = this.decodeToken();
    if (token) {
      const mainRole = findMainRole(token.groups);

      this.profile = {
        id: token.sub,
        userName: token.name,
        fio: this.generateFio(token),
        role: mainRole,
        roleName: get(USER_ROLES, mainRole),
      };
    }
  }

  /**
   * Имя пользователя в формате: Фамилия И. О.
   */
  private generateFio(token: IAuthToken): string {
    return `${token.person.lastName || ''} ${token.person.firstName ? token.person.firstName.charAt(0) + '.' : ''} ${
      token.person.middleName ? token.person.middleName.charAt(0) + '.' : ''
    }`;
  }

  /**
   * Расшифровка JWT токена
   */
  private decodeToken(): IAuthToken | undefined {
    const token = this.accessToken || '';
    if (!token) {
      return undefined;
    }

    try {
      return token ? jwt_decode(token) : undefined;
    } catch (e) {
      this.logger.log('failed decode jwt token', e);
      return undefined;
    }
  }

  /**
   * Проверка токена
   */
  async init() {
    if (!this.accessToken) return;

    this.getUserInfo();

    if (environment.checkExpired) {
      interval(1000)
        .pipe(takeUntil(this.tokenExpired))
        .subscribe(() => {
          if (this.isTokenExpired()) {
            this.tokenExpired.next(null);
            this.tokenExpired.complete();
            this.modal.error({
              nzTitle: MESSAGE_ERROR_TOKEN_EXPIRED,
              nzClosable: false,
              nzOnOk: () => {
                window.location.reload();
              },
            });
          }
        });
    }
  }

  /**
   * Список ролей пользователя
   */
  public getRoles(): string[] {
    const token = this.decodeToken();
    return token?.groups || [];
  }

  /**
   * Токен истек?
   */
  public isTokenExpired(offsetSeconds = 60): boolean {
    const d = this.getTokenExpirationDate();
    offsetSeconds = offsetSeconds || 0;
    if (d === null) {
      return true;
    }

    return !(d.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
  }

  /**
   * Дата истечения токена
   * @private
   */
  private getTokenExpirationDate() {
    const token = this.decodeToken();

    if (!token?.exp) {
      return null;
    }

    const d = new Date(0);
    d.setUTCSeconds(token.exp);

    return d;
  }
}
