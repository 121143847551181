import { Role } from '../consts/roles.enum';

export function findMainRole(roles: string[]) {
  for (const role in Role) {
    if (roles.indexOf(role) !== -1) {
      return role;
    }
  }

  return '';
}
