<ng-container [formGroup]="form" *ngIf="form">
  <filter-condition
    *ngIf="filterCondition.length > 0"
    [form]="form"
    [label]="filter?.label"
    [filterCondition]="filterCondition">
    <nz-form-item>
      <nz-form-control nzErrorTip="Выберите один из вариантов">
        <nz-select formControlName="value" nzShowSearch nzAllowClear [nzPlaceHolder]="'Выберите значение'">
          <ng-container *ngFor="let item of params?.list | async">
            <nz-option [nzValue]="getValue(item)" [nzLabel]="getLabel(item)"></nz-option>
          </ng-container>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </filter-condition>

  <ng-container *ngIf="filterCondition.length === 0">
    <nz-select formControlName="value" nzShowSearch nzAllowClear [nzPlaceHolder]="'Выберите значение'">
      <ng-container *ngFor="let item of params?.list | async">
        <nz-option [nzValue]="getValue(item)" [nzLabel]="getLabel(item)"></nz-option>
      </ng-container>
    </nz-select>
  </ng-container>
</ng-container>
