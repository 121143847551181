import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { cloneDeep } from 'lodash-es';
import { IJournalColumn } from '../types/journal-column.type';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {
  /**
   * Экспорт данных
   * @param columns
   * @param rows
   * @param excelFileName
   */
  public exportAsExcelFile(columns: IJournalColumn[], rows: any[], excelFileName: string): void {
    const columnsClone = cloneDeep(columns);

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.makeJson(columnsClone, rows));
    this.setColumnWidth(worksheet, columnsClone);

    const workbook: XLSX.WorkBook = {
      Sheets: { 'Экспорт данных': worksheet },
      SheetNames: ['Экспорт данных'],
    };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  /**
   * Формируем JSON
   * @param columns
   * @param rows
   */
  private makeJson(columns: IJournalColumn[], rows: any[]) {
    const json = [];

    for (const col of columns) {
      col.maxLength = col.field.length + 1;
    }

    for (const row of rows) {
      const jsonRow: { [key: string]: any } = {};

      for (const col of columns) {
        if (col.visible) {
          const field = col.field,
            label = col.label;

          jsonRow[label] = row[field];
          col.maxLength = Math.max(row[field] ? row[field].length : 0, col.maxLength || 0);
        }
      }

      json.push(jsonRow);
    }

    return json;
  }

  /**
   * Установка ширины
   */
  private setColumnWidth(worksheet: XLSX.WorkSheet, columns: IJournalColumn[]) {
    worksheet['!cols'] = [];
    for (const col of columns) {
      if (col.visible) {
        if (col['width']) {
          worksheet['!cols'].push({
            wpx: +col.width || 100,
          });
        } else {
          worksheet['!cols'].push({
            wch: col.maxLength,
          });
        }
      }
    }
  }

  /**
   * Сохранить файл
   * @param buffer
   * @param fileName
   */
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, (fileName || 'export_' + new Date().getTime()) + EXCEL_EXTENSION);
  }
}
