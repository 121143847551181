import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SidebarLayoutComponent } from 'src/app/layouts/sidebar-layout/sidebar-layout.component';
import { SimpleLayoutComponent } from 'src/app/layouts/simple-layout/simple-layout.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { RouterModule } from '@angular/router';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { Page404Component } from './components/page404/page404.component';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { ModalConfirmCodeModule } from 'src/app/modules/modal-confirm-code/index';

const COMPONENTS = [SimpleLayoutComponent, SidebarLayoutComponent, Page404Component, SidebarMenuComponent];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzLayoutModule,
    NzMenuModule,
    NzToolTipModule,
    NzBreadCrumbModule,
    NzIconModule,
    ModalConfirmCodeModule,
  ],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
})
export class LayoutModule {}
