import { Type } from '@angular/core';

export const JOURNAL_COLUMN_TYPE_TEXT = 'text';
export const JOURNAL_COLUMN_TYPE_COUNT = 'count';
export const JOURNAL_COLUMN_TYPE_DATE = 'date';
export const JOURNAL_COLUMN_TYPE_DATE_TIME = 'datetime';
export const JOURNAL_COLUMN_TYPE_COMPONENT = 'component';
export const JOURNAL_COLUMN_TYPE_HTML = 'html';

export type JournalColumnType =
  | typeof JOURNAL_COLUMN_TYPE_TEXT
  | typeof JOURNAL_COLUMN_TYPE_COUNT
  | typeof JOURNAL_COLUMN_TYPE_DATE
  | typeof JOURNAL_COLUMN_TYPE_DATE_TIME
  | typeof JOURNAL_COLUMN_TYPE_HTML
  | typeof JOURNAL_COLUMN_TYPE_COMPONENT;

export interface IJournalColumn {
  // название
  label: string;
  // аттрибут
  field: string;
  // тип поля, по-умолчанию JOURNAL_COLUMN_TYPE_TEXT
  type?: JournalColumnType;
  // видимость, по-умолчанию false
  visible?: boolean;
  // сортировка
  sorting?: boolean;
  // направление
  sortOrder?: string | null;
  // компонент
  component?: Type<unknown>;
  // функция обработки
  renderFn?: (row: any, col: IJournalColumn) => string;
  // ширина колонки
  width?: string;
  // класс ячейки
  styleClass?: string;
  // ограничить длину
  maxLength?: number;
}
