import { Component, inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as sp from 'sprintf-js';
import { formNestedValidate } from 'src/app/shared/helpers/form';
import { codeMatchesValidator } from 'src/app/shared/validators/codeMatches';
import { BehaviorSubject } from 'rxjs';

interface IModalConfirmCodeData {
  title?: string;
  length?: number;
}

@Component({
  selector: 'modal-confirm-code',
  templateUrl: './modal-confirm-code.component.html',
  styleUrls: ['./modal-confirm-code.component.less'],
})
export class ModalConfirmCodeComponent implements OnInit {
  /**
   * Заголовок
   */
  @Input() title = 'Введите код <strong>%s</strong>';
  /**
   * Длина кода
   */
  @Input() length = 5;

  readonly modal = inject(NzModalRef);
  readonly nzModalData: IModalConfirmCodeData = inject(NZ_MODAL_DATA);

  @ViewChild('modalFooter') public footerTemplate!: TemplateRef<any>;

  form!: FormGroup;
  codeControl!: FormControl;
  wasValidated = false;

  /**
   * Сгенерированный код
   */
  code$: BehaviorSubject<string> = new BehaviorSubject('');

  autoTips = {
    default: {
      required: 'Введите код',
      codeMatches: 'Код не совпадает',
    },
  };

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      code: [null, [Validators.required, codeMatchesValidator(this.code$)]],
    });

    this.codeControl = this.form.get('code') as FormControl;
  }

  ngOnInit() {
    this.length = this.nzModalData.length || this.length;
    this.code$.next(
      Math.random()
        .toString()
        .slice(2, this.length + 2),
    );
    this.title = sp.sprintf(this.nzModalData.title || this.title, this.code$.getValue());
  }

  confirm(): void {
    this.wasValidated = true;
    formNestedValidate(this.form);

    if (this.form.valid) {
      this.modal.close(true);
    }
  }

  close(): void {
    this.modal.close(false);
  }
}
