<form
  nz-form
  novalidate
  autocomplete="off"
  [nzAutoTips]="autoTips"
  [formGroup]="form"
  (ngSubmit)="confirm()"
  (keydown.enter)="confirm()">
  <div class="modal-confirm-code__title" [innerHTML]="title"></div>

  <nz-form-item>
    <nz-form-control [nzValidateStatus]="!wasValidated ? 'success' : codeControl">
      <input class="modal-confirm-code__input" nzSize="large" nz-input formControlName="code" />
    </nz-form-control>
  </nz-form-item>

  <div class="modal-confirm-code__actions">
    <button type="submit" nz-button nzType="primary" (click)="confirm()">Подтвердить</button>
    <button type="button" nz-button nzType="default" (click)="close()">Отменить</button>
  </div>
</form>
