import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { BehaviorSubject, filter, Observable, of, switchMap, take, throwError } from 'rxjs';
import { catchError, delay, finalize, tap } from 'rxjs/operators';

import { Logger } from '../services/logger/logger';
import { environment } from 'src/environments/environment';
import { LOGGER } from '../consts/log.enum';
import { mocks } from 'src/app/mocks';
import { MESSAGE_ERROR_SERVICE_UNAVAILABLE, MESSAGE_ERROR_TOKEN_EXPIRED } from 'src/app/shared/consts/messages.const';
import { AuthService } from 'src/app/pages/auth/services/auth.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AUTH_TOKEN_HEADER_KEY, AUTH_TOKEN_PREFIX, AUTH_TOKEN_SKIP } from 'src/app/shared/consts/http.const';
import { ProfileService } from 'src/app/shared/services/app/profile.service';
import { IAuthLogin } from 'src/app/shared/types/auth.type';
import { get, isNil, negate } from 'lodash-es';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class HttpMockRequestInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(
    private logger: Logger,
    private http: HttpClient,
    private authService: AuthService,
    private modal: NzModalService,
    private profileService: ProfileService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }

    for (const element of mocks) {
      if (request.url !== element.url) {
        continue;
      }

      // есть параметры
      if (element.params) {
        let hasParams = true;

        for (const paramName in element.params) {
          if (element.params[paramName].indexOf(get(request.body, paramName)) === -1) {
            hasParams = false;
          }
        }

        if (!hasParams) {
          return throwError(() => new HttpResponse({ status: 400, body: {} })).pipe(delay(500));
        }
      }

      this.logger.log('%cIntercepted mock services call', LOGGER.request, request.url);
      this.logger.log('%cRequest body', LOGGER.request, request.body);

      return this.http
        .get(`assets/mocks/${element.json}.json`, {
          headers: new HttpHeaders().set(InterceptorSkipHeader, ''),
        })
        .pipe(
          delay(500),
          tap((data) => {
            this.logger.log('%cLoaded from json', LOGGER.requestResult, data);
          }),
          switchMap((data) => of(new HttpResponse({ status: element.httpCode || 200, body: data }))),
        );
    }

    this.logger.log('%cMock not found. Use services call', LOGGER.request, environment.restApi + request.url);

    let newRequest: HttpRequest<unknown>;
    newRequest = request.clone({
      url: environment.restApi + request.url,
    });

    // add token auth
    const accessToken = this.profileService.accessToken;
    if (!newRequest.headers.has(AUTH_TOKEN_SKIP) && accessToken) {
      newRequest = this.addTokenHeader(newRequest, accessToken);
    }

    return next.handle(newRequest).pipe(
      tap((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse && event.body) {
          this.logger.log(`%cService ${request.url} result`, LOGGER.requestResult, event.body);
        }
      }),
      catchError((res) => {
        // для 401
        if (res instanceof HttpErrorResponse && !newRequest.headers.has(AUTH_TOKEN_SKIP) && res.status === 401) {
          return this.handle401Error(newRequest, next);
        }

        // для 500 ошибок - не выводить лог
        if (!(res instanceof HttpErrorResponse) || (res.status && Math.round(res.status / 100) === 5) || !res.error) {
          res.error = {
            error: {
              message: MESSAGE_ERROR_SERVICE_UNAVAILABLE,
            },
          };
        }

        return throwError(res.error);
      }),
    ) as Observable<HttpEvent<unknown>>;
  }

  /**
   * Обработка истекшего токена
   * @param request
   * @param next
   * @private
   */
  private handle401Error(request: HttpRequest<unknown>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.logger.log(`%cRefresh token`, LOGGER.request);
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = this.profileService.refreshToken;
      if (refreshToken) {
        return this.authService.refreshToken(refreshToken).pipe(
          finalize(() => {
            this.isRefreshing = false;
          }),
          switchMap((token: IAuthLogin) => {
            this.profileService.setToken(token);
            this.refreshTokenSubject.next(token.accessToken);
            return next.handle(this.addTokenHeader(request, token.accessToken));
          }),
          catchError((err) => {
            this.profileService.logout();

            this.modal.error({
              nzTitle: MESSAGE_ERROR_TOKEN_EXPIRED,
              nzClosable: false,
              nzOnOk: () => {
                window.location.reload();
              },
            });

            return throwError(err);
          }),
        );
      }
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      filter(negate(isNil)),
      switchMap((token) => next.handle(this.addTokenHeader(request, token))),
    );
  }

  /**
   * Добавляем токен авторизации
   */
  private addTokenHeader(request: HttpRequest<unknown>, token: string) {
    return request.clone({
      headers: request.headers.set(AUTH_TOKEN_HEADER_KEY, AUTH_TOKEN_PREFIX + token),
    });
  }
}
