import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { cloneDeep, concat, findIndex, get, isFunction, isUndefined, negate, remove } from 'lodash-es';
import { finalize, takeUntil } from 'rxjs/operators';

import { JournalService } from '../services/journal.service';
import { ExcelService } from '../services/excel.service';
import { cloneColumns, cloneFilters } from '../utils/helpers';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { IJournal, IJournalManage, IJournalStorage, JournalCheckedType, JournalRowType } from '../types/journal.type';
import { IJournalAction } from '../types/journal-action.type';
import { IJournalFilterParam } from '../types/journal-filter.type';
import {
  IJournalQuery,
  IJournalQueryFilter,
  IJournalQuerySort,
  IJournalResult,
  JOURNAL_QUERY_SORT_ASC,
  JOURNAL_QUERY_SORT_DESC, JOURNAL_QUERY_SORT_MAP,
} from '../types/journal-query.type';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { IJournalColumn } from '../types/journal-column.type';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { format } from 'date-fns';
import { findInList } from '../utils/lodash';
import { findInSet, isBlank } from 'src/app/shared/helpers/lodash';
import { Logger } from 'src/app/shared/services/logger/logger.service';

const MAX_EXPORT_SIZE = 50000;

@Component({
  selector: 'nz-journal',
  templateUrl: './journal.component.html',
  styleUrls: ['./journal.component.less'],
})
export class JournalComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('table') journalTable?: TemplateRef<NzTableComponent<JournalRowType>>;
  /**
   * Имя журнала
   */
  @Input({ required: true }) sysName = 'default';
  /**
   * Конфигурация
   */
  @Input({ required: true }) config!: IJournal;
  /**
   * Кнопки журнала
   */
  @Input() actions: IJournalAction[] = [];
  /**
   * Максимальный размер кнопок
   */
  @Input() maxActions = 0;
  /**
   * Атрибут основного ключа
   */
  @Input() primaryKey = 'id';
  /**
   * Сервис получения данных журнала
   */
  @Input({ required: true }) dataProvider!: string;
  /**
   * Дополнительные параметры запроса
   */
  private _customParams: IJournalQueryFilter[] | null = [];
  @Input() set customParams(value: IJournalQueryFilter[] | null) {
    this._customParams = value;
  }

  get customParams(): IJournalQueryFilter[] | null {
    return this._customParams;
  }

  /**
   * Кол-во объектов на странице
   */
  @Input() perPage = 25;
  /**
   * Запросить данные при старте компонента
   */
  @Input() initWithData = true;
  /**
   * Скролл
   */
  @Input() scroll = { x: '1000px' };
  /**
   * Вариант выбора
   */
  @Input() checked: JournalCheckedType = 'none';
  checkedState = false;
  indeterminate = false;
  setOfChecked$: BehaviorSubject<Set<JournalRowType>> = new BehaviorSubject(new Set<JournalRowType>());
  setOfCheckedId$: BehaviorSubject<Set<number | string>> = new BehaviorSubject(new Set<number | string>());
  /**
   * Показать всего найдено
   */
  @Input() hasShowTotalCount = true;
  /**
   * Показать кнопку экспорта
   */
  @Input() hasExportData = false;
  /**
   * Показать кнопку обновить
   */
  @Input() hasReloadData = false;
  /**
   * Стилизация строки журнала
   */
  @Input() rowClassFunction?: (index: number, data: object) => string | undefined;
  /**
   * Флаги
   */
  isInit = true;
  /**
   * Индикатор загрузки
   */
  isLoadingData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /**
   * Всего строк
   */
  totalCount = 0;
  /**
   * Текущая страница
   */
  pageIndex = 1;
  /**
   * Доступный выбор кол-ва элементов на страницу
   */
  pageSizeOptions = [10, 25, 50, 100, 500];
  /**
   * Выбранные колонки
   */
  columns: IJournalColumn[] = [];
  /**
   * Данные журнала
   */
  rows: JournalRowType[] = [];
  /**
   * Отформатированные данные журнала
   */
  rowsFormat: JournalRowType[] = [];
  /**
   * Фильтр расширенный
   */
  filtersParam: IJournalFilterParam[] = [];
  /**
   * Фильтр в колонках
   */
  filtersInlineParam: IJournalFilterParam[] = [];
  /**
   * Фильтр под колонкой
   */
  isInlineFilter = false;
  /**
   * Фильтры в виде отдельной формы
   */
  isFormFilter = true;
  /**
   * Видимость фильтра
   */
  isFilterVisible = false;
  /**
   * Видимость опций
   */
  isOptionVisible = false;
  /**
   * Сортировка
   */
  sort: IJournalQuerySort[] = [];
  /**
   * Ошибка
   */
  error?: string;

  destroy$: Subject<boolean> = new Subject<boolean>();

  get storeKey() {
    return `journal.${this.sysName}`;
  }

  get startPage() {
    if (this.totalCount === 0) {
      return 0;
    }

    return (this.pageIndex - 1) * this.perPage + 1;
  }

  get endPage() {
    return Math.min(this.pageIndex * this.perPage, this.totalCount);
  }

  /**
   * События
   */
  @Output() selected = new EventEmitter();
  @Output() doubleClick = new EventEmitter();

  constructor(
    private journalService: JournalService,
    private excelService: ExcelService,
    private modalService: NzModalService,
    private storage: LocalStorageService,
    private message: NzMessageService,
    private logger: Logger,
  ) {}

  ngOnInit(): void {
    this.setOfChecked$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      const setOfCheckedId: Set<number | string> = new Set();
      value.forEach((v) => setOfCheckedId.add(this.getKey(v)));
      this.setOfCheckedId$.next(setOfCheckedId);
      this.selected.emit(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const config = changes['config'];
    const initWithData = changes['initWithData'];

    if (config?.currentValue && config?.isFirstChange()) {
      this.isInit = false;

      if (!this.sysName) {
        this.logger.warn('Warning! Journal sysName is needed for save user param');
        return;
      }

      this.config = {
        ...this.config,
        columns: cloneColumns(this.config.columns),
        filters: cloneFilters(this.config.filters || [], this.sysName),
      };

      this.restoreFromUserStorage(config.currentValue);
    }

    if (initWithData?.currentValue) {
      this.loadDataFromServer();
    }
  }

  /**
   * Восстановление параметров настроенного журнала
   */
  private restoreFromUserStorage(config: IJournal) {
    const userParam = this.storage.retrieve(this.storeKey) as IJournalStorage;

    this.isFilterVisible = userParam?.isFilterVisible || false;
    this.isOptionVisible = userParam?.isOptionVisible || false;
    this.perPage = userParam?.perPage || 25;

    this.sort = userParam?.sort || [];

    if (userParam?.columns?.length) {
      this.initColumnsFromStorage(userParam.columns);
    } else {
      this.initColumns(config.columns);
    }

    // могут быть оба варианта одновременно
    if (this.config.filters?.length) {
      // могут быть оба варианта одновременно
      this.isInlineFilter = this.config.filters.findIndex((filter) => filter.inline) !== -1;
      this.isFormFilter = this.config.filters.findIndex((filter) => !filter.inline) !== -1;

      if (userParam?.filters?.length || userParam?.filtersInline?.length) {
        this.initFiltersFromStorage(userParam?.filters || [], userParam?.filtersInline || []);
      } else {
        this.initFilters();
      }
    }
  }

  /**
   * Инициализация колонок
   */
  private initColumns(columns: IJournalColumn[]) {
    this.columns = cloneColumns(columns).filter((col) => col.visible);
  }

  /**
   * Загрузка колонок из настроек
   */
  private initColumnsFromStorage(userParamColumns: string[]) {
    this.columns = userParamColumns
      .map((col) => findInList(this.config?.columns || [], 'field', col))
      .filter(negate(isUndefined)) as IJournalColumn[];

    this.sort?.forEach((sort) => {
      const column = findInList(this.columns, 'field', sort.field);
      if (column) {
        column.sortOrder = JOURNAL_QUERY_SORT_MAP[sort.operator] || null;
      }
    })
  }

  /**
   * Инициализация фильтров
   */
  private initFilters() {
    // формирование структуры фильтра
    this.filtersParam = (this.config.filters || [])
      .filter((filter) => !filter.inline)
      .filter((filter) => filter.visible)
      .filter((filter) => !this.customParams?.find((param) => param.field === filter.field))
      .map((filter) => this.journalService.getParamFilter(filter, !!filter.enable));

    this.filtersInlineParam = (this.config.filters || [])
      .filter((filter) => filter.inline)
      .map((filter) => this.journalService.getParamFilter(filter, true));
  }

  /**
   * Загрузка фильтров из настроек
   */
  private initFiltersFromStorage(
    userParamFilters: IJournalFilterParam[],
    userParamInlineFilters: IJournalFilterParam[],
  ) {
    this.filtersParam = userParamFilters
      .filter((filter) => findInList(this.config?.filters || [], 'field', filter.field))
      .filter((filter) => !this.customParams?.find((param) => param.field === filter.field));

    this.filtersInlineParam = (this.config.filters || [])
      .filter((filter) => filter.inline)
      .map((filter) => this.journalService.getParamFilter(filter, true))
      .map((filter) => {
        const userParamFilter = findInList(userParamInlineFilters, 'field', filter.field);
        if (userParamFilter) {
          filter.value = userParamFilter.value;
          filter.valueStart = userParamFilter.valueStart;
          filter.valueFinish = userParamFilter.valueFinish;
        }

        return filter;
      });
  }

  /**
   * Обновление данных
   */
  reloadData() {
    if (!this.isInit) {
      this.pageIndex = 1;
      this.loadDataFromServer();
    }
    return false;
  }

  /**
   * Запрос для сервера
   */
  prepareQueryRequest(): IJournalQuery {
    return {
      page: this.pageIndex - 1,
      perPage: this.perPage,
      columns: [
        ...['id', 'lockVersion', 'deleted'],
        ...this.columns.map((col) => col.field),
        ...(this.config?.additionalColumns || []),
      ],
      filters: concat(
        this.journalService.prepareQueryInlineFilter(this.filtersInlineParam),
        this.journalService.prepareQueryFilter(this.filtersParam),
        this._customParams || [],
      ),
      order: this.sort,
    };
  }

  /**
   * Получение данных
   */
  loadDataFromServer() {
    this.initWithData = false;
    this.error = undefined;

    if (!this.columns.length) {
      this.error = 'Не выбраны столбцы для отображения';
      this.totalCount = 0;
      this.rows = [];
      this.rowsFormat = [];
      return;
    }

    const query = this.prepareQueryRequest();

    this.isLoadingData$.next(true);
    this.journalService
      .loadData(this.dataProvider, query)
      .pipe(
        finalize(() => {
          this.isLoadingData$.next(false);
          this.resetChecked();
        }),
      )
      .subscribe({
        next: (res: IJournalResult<JournalRowType>) => {
          this.totalCount = res.pagination.totalCount;
          this.rows = res.items;
          this.rowsFormat = this.journalService.formatData(
            this.columns,
            cloneDeep(this.rows),
            this.pageIndex,
            this.perPage,
          );
        },
        error: (err) => {
          this.error = err?.error?.message || 'Ошибка получения данных';
          this.rows = [];
          this.rowsFormat = [];
        },
      });
  }

  /**
   * Изменение настроек журнала
   */
  onUpdate($event: IJournalManage) {
    this.isFilterVisible = $event.isFilterVisible;
    this.isOptionVisible = $event.isOptionVisible;

    if ($event.columns) {
      this.columns = $event.columns;
    }

    if ($event.filters) {
      this.filtersParam = $event.filters || [];
      this.pageIndex = 1;

      let isChange = false;
      for (const filterParam of this.filtersParam) {
        if (filterParam.enable && filterParam.value) {
          const filterInline = this.filtersInlineParam.find((f) => f.field === filterParam.field && isBlank(f.value));
          if (filterInline) {
            filterInline.value = null;
            filterInline.valueStart = null;
            filterInline.valueFinish = null;
            isChange = true;
          }
        }
      }

      if (isChange) {
        this.filtersInlineParam = [...this.filtersInlineParam];
      }
    }

    this.saveUserStorage();

    if ($event.columns || $event.filters) {
      this.loadDataFromServer();
    }
  }

  /**
   * Получаем фильтр
   */
  onFilterInline(filtersParam: IJournalFilterParam[]) {
    this.filtersInlineParam = filtersParam;
    this.pageIndex = 1;

    let isChange = false;
    for (const filterParamInline of this.filtersInlineParam) {
      if (findInList(this.columns, 'field', filterParamInline.field) && filterParamInline.value) {
        const filterParam = this.filtersParam.find((f) => f.field === filterParamInline.field && f?.enable);
        if (filterParam) {
          filterParam.enable = false;
          isChange = true;
        }
      }
    }

    if (isChange) {
      this.filtersParam = [...this.filtersParam];
    }

    this.saveUserStorage();
    this.loadDataFromServer();
  }

  /**
   * Выбор элемента
   */
  onDoubleClick($event: JournalRowType) {
    this.doubleClick.emit($event);
  }

  /**
   * Сортировка
   */
  onSort(column: IJournalColumn, direction: string | null) {
    if (direction) {
      const sortItem: IJournalQuerySort = {
        field: column.field,
        operator: direction === 'ascend' ? JOURNAL_QUERY_SORT_ASC : JOURNAL_QUERY_SORT_DESC,
      };

      const indexSort = findIndex(this.sort, (sort) => sort.field === column.field);

      if (indexSort !== -1) {
        this.sort[indexSort] = sortItem;
      } else {
        this.sort.push(sortItem);
      }
    } else {
      remove(this.sort, (sort) => sort.field === column.field);
    }

    this.saveUserStorage();

    this.loadDataFromServer();
  }

  /**
   * Изменение порядка колонок
   */
  onReorder($event: CdkDragDrop<JournalRowType>) {
    const element = this.columns[$event.previousIndex];
    this.columns.splice($event.previousIndex, 1);
    this.columns.splice($event.currentIndex, 0, element);
    this.saveUserStorage();
  }

  /**
   * Экспорт данных
   */
  exportExcel() {
    if (this.totalCount > MAX_EXPORT_SIZE) {
      this.modalService.confirm({
        nzTitle: 'Обнаружено слишком много записей!',
        nzContent: `Для экспорта журнала существует ограничение на не более чем ${MAX_EXPORT_SIZE} записей. Продолжить?`,
        nzOnOk: () => {
          this.exportData();
        },
      });
    } else {
      this.exportData();
    }

    return false;
  }

  /**
   * Экспорт данных. Формирование XLS
   */
  private exportData() {
    this.isLoadingData$.next(true);

    const query = this.prepareQueryRequest();
    query.page = 0;
    query.perPage = MAX_EXPORT_SIZE;

    this.journalService
      .loadData(this.dataProvider, query)
      .pipe(finalize(() => this.isLoadingData$.next(false)))
      .subscribe({
        next: (res: IJournalResult<JournalRowType>) => {
          let rows: JournalRowType[];
          if (res.items) {
            rows = this.journalService.formatData(this.columns, res.items, 1, MAX_EXPORT_SIZE);
          } else {
            rows = [];
          }
          this.excelService.exportAsExcelFile(
            this.columns,
            rows,
            `export_${format(new Date(), 'yyyyMMddHHmmss')}.xlsx`,
          );
        },
        error: (error) => {
          this.message.error(error.error?.message || 'Ошибка загрузки данных. Повторите запрос позднее.');
        },
      });
  }

  /**
   * Сохранение настроенного журнала
   */
  private saveUserStorage() {
    if (this.sysName) {
      this.storage.store(this.storeKey, {
        filters: this.filtersParam,
        filtersInline: this.filtersInlineParam,
        sort: this.sort,
        columns: this.columns.map((col) => col.field),
        isFilterVisible: this.isFilterVisible,
        isOptionVisible: this.isOptionVisible,
        perPage: this.perPage,
      });
    }
  }

  /**
   * Безопасное получение значения вложенного объекта
   */
  getValue(field: string, data: JournalRowType): string | number {
    return get(data, field, '');
  }

  /**
   * Безопасное получение значения вложенного объекта
   */
  getKey(data: JournalRowType): string | number {
    return this.getValue(this.primaryKey, data);
  }

  /**
   * Класс для строки
   */
  getRowClassFunction(index: number, data: JournalRowType): undefined | string {
    if (this.rowClassFunction) {
      return this.rowClassFunction(index, data);
    }

    return undefined;
  }

  /**
   * Выбрать все
   */
  onAllChecked(checked: boolean): void {
    this.rows.forEach((row) => this.updateCheckedSet(row, checked));
    this.refreshCheckedStatus();
  }

  /**
   * Обновить статус выбранного
   */
  refreshCheckedStatus(): void {
    const setOfChecked = this.setOfChecked$.getValue();
    this.checkedState = this.rows.every((row) => {
      const rowKey = this.getKey(row);
      return findInSet(setOfChecked, (e) => this.getKey(e) === rowKey);
    });
    this.indeterminate =
      this.rows.some((row) => {
        const rowKey = this.getKey(row);
        return findInSet(setOfChecked, (e) => this.getKey(e) === rowKey);
      }) && this.checked === 'none';
  }

  /**
   * Выбрать строку
   */
  onItemChecked(row: JournalRowType, checked: boolean): void {
    this.updateCheckedSet(row, checked);
    this.refreshCheckedStatus();
  }

  /**
   * Выбрать строку
   */
  onItemCheckedSingle(row: JournalRowType, checked: boolean): void {
    const originalRow = this.getOriginalRow(row);
    this.setOfChecked$.next(new Set());
    this.updateCheckedSet(originalRow, checked);
    this.refreshCheckedStatus();
  }

  /**
   * Обновление статусы выбранной строки
   */
  updateCheckedSet(row: JournalRowType, checked: boolean): void {
    const originalRow = this.getOriginalRow(row);
    const setOfChecked = this.setOfChecked$.getValue();

    if (checked) {
      setOfChecked.add(originalRow);
    } else {
      const rowKey = this.getKey(originalRow);
      const rowInSet = findInSet(setOfChecked, (checkedRow) => rowKey === this.getKey(checkedRow));
      setOfChecked.delete(rowInSet);
    }

    this.setOfChecked$.next(setOfChecked);
  }

  private getOriginalRow(row: JournalRowType) {
    return this.rows.find((item) => get(item, this.primaryKey) === get(row, this.primaryKey));
  }

  /**
   * Сброс выбранных элементов
   */
  resetChecked() {
    this.checkedState = false;
    this.indeterminate = false;
  }

  /**
   * Показывать ли кнопку
   */
  isVisibleRowButton(button: IJournalAction, row: JournalRowType) {
    if (isFunction(button.hideFn)) {
      return !button.hideFn(row);
    }

    return true;
  }

  /**
   * Переход по страницам
   */
  onPageIndexChange($event: number) {
    this.pageIndex = $event;
    this.loadDataFromServer();
  }

  /**
   * Изменения размера страниц
   */
  onPageSizeChange($event: number) {
    this.perPage = $event;
    this.saveUserStorage();
    this.loadDataFromServer();
  }

  /**
   * Уничтожение
   */
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
