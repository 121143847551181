import { IJournalFilterCondition, JournalFilterConditionType } from '../../types/journal-filter.type';

export const FILTER_CONDITION_TEXT: IJournalFilterCondition[] = [
  {
    name: 'соответствует',
    sysName: JournalFilterConditionType.equal,
  },
  {
    name: 'не соответствует',
    sysName: JournalFilterConditionType.notEqual,
  },
  {
    name: 'содержит',
    sysName: JournalFilterConditionType.ilike,
  },
  {
    name: 'отсутствует',
    sysName: JournalFilterConditionType.isNull,
  },
];
