import { ModuleWithProviders, NgModule } from '@angular/core';
import { Level } from './level.enum';
import { Logger, LOGGER_LEVEL } from './logger.service';

@NgModule()
export class LoggerModule {
  /**
   * Provide the {@link Logger} with the given log {@link Level}.
   * @param level The log level.
   */
  static forRoot(level: Level = Level.LOG): ModuleWithProviders<any> {
    return {
      ngModule: LoggerModule,
      providers: [Logger, { provide: LOGGER_LEVEL, useValue: level }],
    };
  }
}
