<div class="journal-filter">
  <div class="journal-filter__row">
    <form nz-form [formGroup]="form" (keydown.enter)="enterFrom($event)">
      <ng-container *ngFor="let filter of filters">
        <div [ngSwitch]="filter.type" class="journal-filter__col">
          <!-- Строковое поле -->
          <ng-template ngSwitchCase="text">
            <filter-text [filter]="filter" [form]="getControl(filter.field)"></filter-text>
          </ng-template>
          <!-- Число -->
          <ng-template ngSwitchCase="number">
            <filter-number [filter]="filter" [form]="getControl(filter.field)"></filter-number>
          </ng-template>
          <!-- Дата -->
          <ng-template ngSwitchCase="date">
            <filter-date [filter]="filter" [form]="getControl(filter.field)"></filter-date>
          </ng-template>
          <!-- Дата и время -->
          <ng-template ngSwitchCase="datetime">
            <filter-datetime [filter]="filter" [form]="getControl(filter.field)"></filter-datetime>
          </ng-template>
          <!-- Список -->
          <ng-template ngSwitchCase="select">
            <filter-select [filter]="filter" [form]="getControl(filter.field)"></filter-select>
          </ng-template>
          <!-- Множественный Список -->
          <ng-template ngSwitchCase="multiselect">
            <filter-multiselect [filter]="filter" [form]="getControl(filter.field)"></filter-multiselect>
          </ng-template>
          <!-- Чекбок -->
          <ng-template ngSwitchCase="checkbox">
            <filter-checkbox [filter]="filter" [form]="getControl(filter.field)"></filter-checkbox>
          </ng-template>
          <!-- Журнал -->
          <ng-template ngSwitchCase="reference">
            <filter-reference [filter]="filter" [form]="getControl(filter.field)"></filter-reference>
          </ng-template>

          <a class="journal-filter__remove" (click)="onRemove(filter)">
            <span nz-icon nzType="close" nzTheme="outline"></span>
          </a>
        </div>
      </ng-container>
    </form>
  </div>

  <div class="journal-filter__add">
    <label>Добавить фильтр</label>
    <nz-select nzShowSearch [formControl]="selectedFilter" [nzDisabled]="!!(isLoadingData$ | async)">
      <ng-container *ngFor="let f of availableFilter">
        <nz-option [nzLabel]="f.label" [nzValue]="f" [nzDisabled]="isSelected(f)"></nz-option>
      </ng-container>
    </nz-select>
  </div>
</div>
