import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzDatePickerMaskComponent } from './nz-date-picker-mask.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzOverlayModule } from 'ng-zorro-antd/core/overlay';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NzInputModule,
    NzIconModule,
    NzDatePickerModule,
    OverlayModule,
    NzOverlayModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  declarations: [NzDatePickerMaskComponent],
  exports: [NzDatePickerMaskComponent],
  providers: [provideNgxMask()],
})
export class NzDatePickerMaskModule {}
