import { Component, OnInit } from '@angular/core';
import { FilterBaseComponent } from '../base/filter-base.component';
import { FILTER_CONDITION_NUMBER } from '../base/filter-condition-number.const';

@Component({
  selector: 'filter-number',
  templateUrl: './filter-number.component.html',
  styleUrls: ['../base/filter-base.components.less', './filter-number.component.less'],
})
export class FilterNumberComponent extends FilterBaseComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
    if (!this.filter) return;

    if (!this.filter.inline) {
      this.filterCondition = this.filter.condition || FILTER_CONDITION_NUMBER;
    }

    this.init();
  }
}
