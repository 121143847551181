import { Component, OnInit } from '@angular/core';
import { FilterBaseComponent } from '../base/filter-base.component';
import { FILTER_CONDITION_TEXT } from '../base/filter-condition-text.const';
import { JournalFilterConditionType } from '../../types/journal-filter.type';

@Component({
  selector: 'filter-text',
  templateUrl: './filter-text.component.html',
  styleUrls: ['../base/filter-base.components.less', './filter-text.component.less'],
})
export class FilterTextComponent extends FilterBaseComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
    if (!this.filter) return;

    if (!this.filter.inline) {
      this.filterCondition = this.filter.condition || FILTER_CONDITION_TEXT;
    }

    this.init();
  }

  protected readonly JournalFilterConditionType = JournalFilterConditionType;
}
