import { CrosshairPlugin } from 'chartjs-plugin-crosshair';

CrosshairPlugin.drawZoombox = function (chart: any) {
  for (let chartIndex = 0; chartIndex < chart.data.datasets.length; chartIndex++) {
    const meta = chart.getDatasetMeta(chartIndex);
    const yScale = chart.scales[meta.yAxisID];

    const borderColor = this.getOption(chart, 'zoom', 'zoomboxBorderColor');
    const fillColor = this.getOption(chart, 'zoom', 'zoomboxBackgroundColor');

    chart.ctx.beginPath();
    chart.ctx.rect(
      chart.crosshair.dragStartX,
      yScale.getPixelForValue(yScale.max),
      chart.crosshair.x - chart.crosshair.dragStartX,
      yScale.getPixelForValue(yScale.min) - yScale.getPixelForValue(yScale.max),
    );
    chart.ctx.lineWidth = 1;
    chart.ctx.strokeStyle = borderColor;
    chart.ctx.fillStyle = fillColor;
    chart.ctx.fill();
    chart.ctx.fillStyle = '';
    chart.ctx.stroke();
    chart.ctx.closePath();
  }
};

CrosshairPlugin.drawTraceLine = function (chart: any) {
  for (let chartIndex = 0; chartIndex < chart.data.datasets.length; chartIndex++) {
    const meta = chart.getDatasetMeta(chartIndex);
    const yScale = chart.scales[meta.yAxisID];

    const lineWidth = this.getOption(chart, 'line', 'width');
    const color = this.getOption(chart, 'line', 'color');
    const dashPattern = this.getOption(chart, 'line', 'dashPattern');
    const snapEnabled = this.getOption(chart, 'snap', 'enabled');

    let lineX = chart.crosshair.x;

    if (snapEnabled && chart._active.length) {
      lineX = chart._active[0].element.x;
    }

    chart.ctx.beginPath();
    chart.ctx.setLineDash(dashPattern);
    chart.ctx.moveTo(lineX, yScale.getPixelForValue(yScale.max));
    chart.ctx.lineWidth = lineWidth;
    chart.ctx.strokeStyle = color;
    chart.ctx.lineTo(lineX, yScale.getPixelForValue(yScale.min));
    chart.ctx.stroke();
    chart.ctx.setLineDash([]);
  }
};

export default CrosshairPlugin;
