<ng-container *ngIf="!isInit">
  <journal-manage
    [config]="config"
    [filtersParam]="filtersParam"
    [columns]="columns"
    [customParams]="customParams"
    [isLoadingData$]="isLoadingData$"
    [isFormFilter]="isFormFilter"
    [isFilterVisible]="isFilterVisible"
    [isOptionVisible]="isOptionVisible"
    (update)="onUpdate($event)">
    <div class="journal-action">
      <ng-content select=".journal-action-prepend"></ng-content>
      <button
        *ngIf="hasReloadData"
        type="button"
        nz-button
        nzType="default"
        nz-tooltip
        nzTooltipTitle="Обновить"
        (click)="reloadData()">
        <i nz-icon nzType="reload" nzTheme="outline"></i>
      </button>
      <button
        *ngIf="hasExportData"
        type="button"
        nz-button
        nzType="default"
        nz-tooltip
        nzTooltipTitle="Экспорт данных"
        (click)="exportExcel()">
        <i nz-icon nzType="file-excel" nzTheme="outline"></i>
      </button>
      <ng-content select=".journal-action-append"></ng-content>
    </div>
  </journal-manage>

  <nz-alert *ngIf="error" nzType="error" [nzMessage]="error"></nz-alert>

  <nz-table
    #table
    [nzScroll]="scroll"
    nzBordered
    nzShowSizeChanger
    [nzData]="rowsFormat"
    [nzLoading]="isLoadingData$ | async"
    [nzFrontPagination]="false"
    [nzTotal]="totalCount"
    [nzPageSize]="perPage"
    [nzPageIndex]="pageIndex"
    [nzPageSizeOptions]="pageSizeOptions"
    (nzPageIndexChange)="onPageIndexChange($event)"
    (nzPageSizeChange)="onPageSizeChange($event)"
    [nzFooter]="hasShowTotalCount ? journalTotalCountTpl : null"
    nzTableLayout="fixed">
    <thead>
      <tr cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="onReorder($event)">
        <th
          *ngIf="checked === 'checkbox'"
          [nzWidth]="'40px'"
          [nzChecked]="checkedState"
          [nzIndeterminate]="indeterminate"
          (nzCheckedChange)="onAllChecked($event)"></th>
        <th *ngIf="checked === 'radio'" [nzWidth]="'40px'"></th>
        <ng-container *ngFor="let column of columns; let columnIndex = index">
          <th
            cdkDrag="false"
            [resizeColumn]="false"
            [cdkDragData]="columnIndex"
            [nzShowSort]="column.sorting !== false"
            [nzSortOrder]="column.sortOrder || null"
            (nzSortOrderChange)="onSort(column, $event)"
            [nzWidth]="column.width ?? null">
            {{ column.label }}
          </th>
        </ng-container>
        <th
          *ngIf="actions.length"
          nzRight
          [nzWidth]="(actions.length === 1 ? 40 : 30 * (maxActions || actions.length) + 8) + 'px'"></th>
      </tr>
    </thead>
    <tbody>
      <journal-filter-column
        *ngIf="isInlineFilter"
        [filters]="config.filters || []"
        [columns]="columns"
        [filtersParam]="filtersInlineParam"
        [checked]="checked !== 'none'"
        [hasActions]="!!actions.length"
        class="ant-table-row"
        (doFilter)="onFilterInline($event)"></journal-filter-column>
      <tr
        *ngFor="let data of table.data; let $index = index"
        [class]="getRowClassFunction($index, data)"
        (dblclick)="onDoubleClick(rows[$index])">
        <td
          *ngIf="checked === 'checkbox'"
          [nzChecked]="(setOfCheckedId$ | async)?.has(data[primaryKey]) || false"
          [nzDisabled]="data['disabled']"
          (nzCheckedChange)="onItemChecked(data, $event)"></td>
        <td *ngIf="checked === 'radio'">
          <journal-checked-radio
            name="radio"
            [value]="getValue(primaryKey, data)"
            [checked]="!!(setOfCheckedId$ | async)?.has(getValue(primaryKey, data))"
            (changeState)="onItemCheckedSingle(data, $event)"></journal-checked-radio>
        </td>
        <ng-container *ngFor="let column of columns">
          <td [class]="column.styleClass ? column.styleClass : null">
            <ng-container [ngSwitch]="column.type">
              <ng-template
                *ngIf="column.component"
                ngSwitchCase="component"
                [ngComponentOutlet]="column.component"
                [ndcDynamicInputs]="{ row: data, column: column, journal: this }"
                [ndcDynamicOutputs]="{}"></ng-template>
              <ng-template ngSwitchCase="html">
                <span [innerHTML]="column.renderFn ? column.renderFn(data, column) : data[column.field]"></span>
              </ng-template>
              <ng-template ngSwitchDefault>
                {{ getValue(column.field, data) }}
              </ng-template>
            </ng-container>
          </td>
        </ng-container>
        <td *ngIf="actions.length" nzRight class="action-column">
          <ng-container *ngFor="let button of actions">
            <a
              *ngIf="isVisibleRowButton(button, rows[$index])"
              [class]="button.styleClass ? button.styleClass : 'text-info'"
              nz-tooltip
              [nzTooltipTitle]="button.title"
              (click)="button.onClick(rows[$index])">
              <i nz-icon [nzType]="button.icon" nzTheme="outline"></i>
            </a>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </nz-table>

  <ng-template #journalTotalCountTpl>
    <span class="journal-total-count">
      Показаны записи <strong>{{ startPage }}-{{ endPage }}</strong> из <strong>{{ totalCount }}</strong>
    </span>
  </ng-template>
</ng-container>
