export const MESSAGE_ERROR_SERVICE_UNAVAILABLE = 'Сервис временно недоступен, обратитесь к администратору';
export const MESSAGE_ERROR_TOKEN_EXPIRED = 'Соединение было разорвано, поскольку Вы слишком долго отсутствовали';

export const MESSAGE_NOT_SAVED_DATA = 'Все несохраненные данные будут утеряны!';
export const MESSAGE_CONTINUE = 'Продолжить';
export const MESSAGE_CANCEL = 'Отмена';
export const MESSAGE_REMOVE_CONFIRM = 'Вы действительно хотите удалить запись?';
export const MESSAGE_REMOVE_SUCCESS = 'Данные успешно удалены.';
export const MESSAGE_RESTORE_SUCCESS = 'Данные успешно восстановлены.';

export const MESSAGE_SAVE_SUCCESS = 'Данные успешно сохранены.';
export const MESSAGE_SAVE_ERROR = 'Ошибка сохранения. Пожалуйста, повторите запрос позже.';

export const MESSAGE_LOAD_ERROR = 'Ошибка загрузки данных.';
