import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isNil } from 'lodash-es';
import { JournalFilterConditionType } from '../types/journal-filter.type';

export const dateValidator = (condition: AbstractControl): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean | string } | null => {
    if (!condition) {
      return null;
    }

    if (!condition.dirty) {
      return null;
    }

    // если одна дата
    if (
      !control.value &&
      (isNil(condition.value) ||
        (condition.value &&
          [
            JournalFilterConditionType.equal,
            JournalFilterConditionType.before,
            JournalFilterConditionType.after,
            JournalFilterConditionType.lessEqual,
            JournalFilterConditionType.moreEqual,
          ].indexOf(condition.value) !== -1))
    ) {
      return {
        required: true,
      };
    }

    return null;
  };
};

export const dateBetweenValidator = (condition: AbstractControl): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean | string } | null => {
    if (!condition) {
      return null;
    }

    // если нет значения
    if (!control.value && condition.value === 'between') {
      return {
        required: true,
      };
    }

    // TODO: check date

    return null;
  };
};

export const rangeNumberValidator = (condition: AbstractControl): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean | string } | null => {
    if (!condition) {
      return null;
    }

    // если нет значения
    if (!control.value && condition.value === 'between') {
      return {
        required: true,
      };
    }

    // TODO: check range

    return null;
  };
};
