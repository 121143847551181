import { IJournalFilterCondition, JournalFilterConditionType } from '../../types/journal-filter.type';

export const FILTER_CONDITION_REFERENCE: IJournalFilterCondition[] = [
  {
    name: 'соответствует',
    sysName: JournalFilterConditionType.equal,
  },
  {
    name: 'не соответствует',
    sysName: JournalFilterConditionType.notEqual,
  },
];
