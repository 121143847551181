'use strict';

function getPluralNoun(num: number, ...wordForms: string[]) {
  let str;

  switch (wordForms.length) {
    case 1:
      throw new Error('Not enough word forms');

    case 2:
      str = num > 1 ? wordForms[1] : wordForms[0];
      break;

    default:
      str = wordForms[getNounPluralForm(num)];
      break;
  }

  return str.replace(/%d/g, '' + num);
}

function getPluralVerb(num: number, ...wordForms: string[]) {
  const str = wordForms[getVerbPluralForm(num)];

  return str.replace(/%d/g, '' + num);
}

function getVerbPluralForm(a: number) {
  if (a > 1000000) {
    return 2;
  }

  if (a > 1000 && a < 1000000 && /000$/.test('' + a)) {
    a /= 1000;
  }

  if ((a % 10 === 1 && a % 100 !== 11) || /1000$/.test(a.toString())) {
    return 0;
  } else if (a % 10 >= 2 && a % 10 <= 4 && (a % 100 < 10 || a % 100 >= 20)) {
    return 1;
  } else {
    return 2;
  }
}

function getNounPluralForm(a: number) {
  if (a % 10 === 1 && a % 100 !== 11) {
    return 0;
  } else if (a % 10 >= 2 && a % 10 <= 4 && (a % 100 < 10 || a % 100 >= 20)) {
    return 1;
  } else {
    return 2;
  }
}

export default getPluralNoun;
export const noun = getPluralNoun;
export const verb = getPluralVerb;
