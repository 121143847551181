<a nz-tooltip nzTooltipTitle="Выше" class="journal-column-sorting__btn" (click)="handleUp()">
  <i nz-icon>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.996 511.996">
      <path
        d="M441.154 176.9L261.954 2.419a8.53 8.53 0 00-11.904 0L70.85 176.9a8.503 8.503 0 00-2.586 6.11c0 2.304.939 4.506 2.586 6.11l48.666 47.386a8.512 8.512 0 0011.921-.017l81.894-80.299v347.273a8.536 8.536 0 008.533 8.533h68.267a8.536 8.536 0 008.533-8.533V156.19l81.894 80.299c3.311 3.251 8.61 3.243 11.93.017l48.666-47.386a8.528 8.528 0 000-12.22z" />
    </svg>
  </i>
</a>
<a nz-tooltip nzTooltipTitle="Ниже" class="journal-column-sorting__btn" (click)="handleDown()">
  <i nz-icon>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path
        d="M441.156 322.876L392.49 275.49a8.523 8.523 0 00-11.93.017l-81.894 80.299V8.533A8.536 8.536 0 00290.133 0h-68.267a8.536 8.536 0 00-8.533 8.533v347.273l-81.894-80.299a8.528 8.528 0 00-11.921-.017l-48.666 47.386a8.503 8.503 0 00-2.586 6.11c0 2.304.939 4.506 2.586 6.11l179.2 174.481A8.508 8.508 0 00256 512c2.15 0 4.292-.811 5.956-2.423l179.2-174.481a8.526 8.526 0 002.577-6.11c0-2.304-.93-4.506-2.577-6.11z" />
    </svg>
  </i>
</a>
