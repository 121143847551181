import { Component, OnInit } from '@angular/core';
import { FilterBaseComponent } from '../base/filter-base.component';
import { FILTER_CONDITION_CHECKBOX } from '../base/filter-condition-checkbox.const';

@Component({
  selector: 'filter-checkbox',
  templateUrl: './filter-checkbox.component.html',
  styleUrls: ['../base/filter-base.components.less', './filter-checkbox.component.less'],
})
export class FilterCheckboxComponent extends FilterBaseComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
    if (!this.filter) return;

    if (!this.filter.inline) {
      this.filterCondition = this.filter.condition || FILTER_CONDITION_CHECKBOX;

      // значение по умолчанию
      // this.form.get('condition')?.setValue(JournalFilterConditionType.equal);
    }

    this.init();
  }
}
