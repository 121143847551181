import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IJournalFilterCondition } from '../../types/journal-filter.type';

@Component({
  selector: 'filter-condition',
  templateUrl: './filter-condition.component.html',
  styleUrls: ['../base/filter-base.components.less'],
})
export class FilterConditionComponent {
  /**
   * Форма
   */
  @Input({ required: true }) form?: FormGroup;
  /**
   * Название
   */
  @Input({ required: true }) label?: string;
  /**
   * Условия
   */
  @Input({ required: true }) filterCondition?: IJournalFilterCondition[];
}
