import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { NzTransferSelectSortingComponent } from './nz-transfer-select-sorting.component';

@NgModule({
  imports: [CommonModule, FormsModule, NzSelectModule, NzButtonModule, NzIconModule],
  declarations: [NzTransferSelectSortingComponent],
  exports: [NzTransferSelectSortingComponent],
})
export class NzTransferSelectSortingModule {}
