import { cloneDeep, isUndefined } from 'lodash-es';
import {
  IJournalFilter,
  IJournalFilterDataJournal,
  IJournalFilterDataSelect,
  JOURNAL_FILTER_TYPE_TEXT,
} from '../types/journal-filter.type';
import { IJournalColumn, JOURNAL_COLUMN_TYPE_TEXT } from '../types/journal-column.type';

/**
 * Инициализация колонок журнала
 */
export function cloneColumns(columns: IJournalColumn[]) {
  return cloneDeep(columns).map((col) => {
    if (isUndefined(col.type)) {
      col.type = JOURNAL_COLUMN_TYPE_TEXT;
    }

    return col;
  });
}

/**
 * Инициализация настроек фильтров
 */
export function cloneFilters(filters: IJournalFilter[], sysName: string) {
  return cloneDeep(filters).map((filter, index) => {
    if (isUndefined(filter.type)) {
      filter.type = JOURNAL_FILTER_TYPE_TEXT;
    }

    // сохраняем ссылку на Observable
    if ((filters[index].params as IJournalFilterDataSelect)?.list) {
      filter.params = filters[index].params;
    }

    const paramsReference = filter.params as IJournalFilterDataJournal;
    if (paramsReference?.sysName) {
      paramsReference.sysName = `${sysName}.${paramsReference.sysName}`;
    }

    return filter;
  });
}

/**
 * Имя контрола
 * @param field
 */
export function controlName(field: string) {
  return field.replaceAll('.', '_');
}
