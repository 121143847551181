import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { ProfileService } from '../shared/services/app/profile.service';
import { SessionService } from '../shared/services/app/session.service';
import { ROUTE_AUTH } from 'src/app/shared/consts/routes.const';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private profileService: ProfileService,
    private session: SessionService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.isAccessAllowed(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(route, state);
  }

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let permission = false;
      if (this.profileService.isLoggedIn()) {
        const requiredRoles: string[] = route.data['roles'];
        if (!requiredRoles || requiredRoles.length === 0) {
          permission = true;
        } else {
          permission = requiredRoles.some((role) => this.profileService.getRoles().indexOf(role) > -1);
        }
      }

      if (!permission) {
        this.profileService.logout();
        this.router.navigate([`/${ROUTE_AUTH}/login`]);
        reject(false);
      }

      resolve(permission);
    });
  }
}
