<ng-container *ngIf="form" [formGroup]="form">
  <filter-condition
    *ngIf="filterCondition.length > 0"
    [form]="form"
    [label]="filter?.label"
    [filterCondition]="filterCondition">
    <nz-form-item *ngIf="form.get('condition')?.value !== JournalFilterConditionType.isNull">
      <nz-form-control nzErrorTip="Укажите значение">
        <input type="text" autocomplete="off" nz-input formControlName="value" />
      </nz-form-control>
    </nz-form-item>
  </filter-condition>

  <ng-container *ngIf="filterCondition.length === 0">
    <input type="text" autocomplete="off" nz-input formControlName="value" [placeholder]="filter?.label" />
  </ng-container>
</ng-container>
