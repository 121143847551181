import { IJournalFilterCondition, JournalFilterConditionType } from '../../types/journal-filter.type';

export const FILTER_CONDITION_DATE: IJournalFilterCondition[] = [
  {
    name: 'больше или равно',
    sysName: JournalFilterConditionType.moreEqual,
  },
  {
    name: 'больше',
    sysName: JournalFilterConditionType.after,
  },
  {
    name: 'меньше или равно',
    sysName: JournalFilterConditionType.lessEqual,
  },
  {
    name: 'меньше',
    sysName: JournalFilterConditionType.before,
  },
  {
    name: 'равно',
    sysName: JournalFilterConditionType.equal,
  },
  {
    name: 'не равно',
    sysName: JournalFilterConditionType.notEqual,
  },
  {
    name: 'между',
    sysName: JournalFilterConditionType.between,
  },
];
