<ng-container *ngIf="form" [formGroup]="form">
  <filter-condition
    *ngIf="filterCondition.length > 0"
    [form]="form"
    [label]="filter?.label"
    [filterCondition]="filterCondition">
    <nz-form-item *ngIf="viewValue === 'one'">
      <nz-form-control nzErrorTip="Укажите значение">
        <nz-input-number formControlName="value" />
      </nz-form-control>
    </nz-form-item>
    <ng-container *ngIf="viewValue === 'some'" nz-row [nzGutter]="6">
      <div nz-col class="gutter-col" [nzSpan]="12">
        <nz-form-item>
          <nz-form-control nzErrorTip="Укажите значение">
            <nz-input-number formControlName="valueStart" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col class="gutter-col" [nzSpan]="12">
        <nz-form-item>
          <nz-form-control nzErrorTip="Укажите значение">
            <nz-input-number formControlName="valueFinish" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </ng-container>
  </filter-condition>

  <ng-container *ngIf="filterCondition.length === 0">
    <ng-container *ngIf="viewValue === 'one'">
      <nz-input-number formControlName="value" />
    </ng-container>
    <ng-container *ngIf="viewValue === 'some'">
      <input
        type="text"
        autocomplete="off"
        nz-input
        [placeholder]="'c ' + filter?.label"
        formControlName="valueStart" />
      <input
        type="text"
        autocomplete="off"
        nz-input
        [placeholder]="'по ' + filter?.label"
        formControlName="valueFinish" />
    </ng-container>
  </ng-container>
</ng-container>
